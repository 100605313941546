import { Component, Input, OnInit } from "@angular/core"
import { ArcRotateCamera } from "@babylonjs/core/Cameras/arcRotateCamera"
import { Ooi } from "src/app/models"
import { BabylonEngineService } from "src/app/services/babylon-engine.service"

@Component({
  selector: "app-debug-dialog",
  templateUrl: "./debug-dialog.component.html",
  styleUrls: ["./debug-dialog.component.scss"]
})
export class DebugDialogComponent implements OnInit {
  public __ooi?: Ooi = undefined
  public currentCameraPosition?: any
  public get originOffset() {
    if (this.__ooi === undefined) {
      return ""
    }
    const offset = {
      x: +this.__ooi.originNode.position.x.toFixed(3),
      y: +this.__ooi.originNode.position.y.toFixed(3),
      z: +this.__ooi.originNode.position.z.toFixed(3)
    }
    return `"originOffset": ${JSON.stringify(offset)},`
  }

  @Input() public set ooi(value: Ooi | undefined) {
    this.__ooi = value
  }

  public constructor(
    private bsEngineService: BabylonEngineService
  ) { }

  public ngOnInit(): void {
    this.bsEngineService.camera?.onViewMatrixChangedObservable.add((el: unknown) => {
      const camera = el as ArcRotateCamera
      const cameraPosition =
        {
        x: +camera.target.x.toFixed(2),
        y: +camera.target.y.toFixed(2),
        z: +camera.target.z.toFixed(2),
        alpha: +camera.alpha.toFixed(2),
        beta: +camera.beta.toFixed(2),
        radius: +camera.radius.toFixed(2)
      }
      this.currentCameraPosition = "\"preferredCameraSettings\": " + JSON.stringify(cameraPosition) + ","
    })
  }

  public async copyToClipBoard(value: any) {
    await navigator.clipboard.writeText(value)

  // Alert the copied text
    alert("Copied coordinates to clipboard")
  }
}
