import { ChangeDetectionStrategy, Component } from "@angular/core"
import { DARK } from "../../environments/theme"

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent {
  public dark = DARK
}
