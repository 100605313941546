
<ng-container *ngFor="let window of windows(); let i = index; trackBy:windowId">

  <div #window #cdkDragRef="cdkDrag"
  [ngStyle]="{
    'width.px': window.size.x,
    'height.px': window.size.y,

  }"
  class="dialog" cdkDrag cdkDragBoundary=".wrapper" #drag="cdkDrag" (mousedown)="toFront(i)" (cdkDragEnded)="dragEnded()" (cdkDragStarted)="dragStarted()" [cdkDragFreeDragPosition]="window.startPosition">
    <div class="header" cdkDragHandle>
      <h2>{{window.title ?? ""}}</h2>
      <div class="item-buttons">
        <button type="button" class="btn btn-link medium-icon-btn" (click)="requestClosing(window.id)"> <i
          class="siemens-close-small"></i> </button>

      </div>
    </div>
    <div class="content mx-5 mb-5">
      <ng-container *ngComponentOutlet="window.comp; inputs: window.inputs"></ng-container>
    </div>
<!--
    <div class="footer m-3">
      Test

    </div>
-->
<span #dragHandleLeft cdkDragLockAxis="x" class="dragHandle left" cdkDrag (cdkDragStarted)="dragStarted()" (cdkDragEnded)="dragEnded()" (cdkDragMoved)="dragMove(dragHandleLeft, i, $event, cdkDragRef)"></span>
<span #dragHandleRight cdkDragLockAxis="x" class="dragHandle right" cdkDrag (cdkDragStarted)="dragStarted()" (cdkDragEnded)="dragEnded()" (cdkDragMoved)="dragMove(dragHandleRight, i, $event, cdkDragRef)"></span>
<span #dragHandleTop cdkDragLockAxis="y" class="dragHandle top" cdkDrag (cdkDragStarted)="dragStarted()" (cdkDragEnded)="dragEnded()" (cdkDragMoved)="dragMove(dragHandleTop, i, $event, cdkDragRef)"></span>
<span #dragHandleBottom cdkDragLockAxis="y" class="dragHandle bottom" cdkDrag (cdkDragStarted)="dragStarted()" (cdkDragEnded)="dragEnded()" (cdkDragMoved)="dragMove(dragHandleBottom, i, $event, cdkDragRef)"></span>
<span #dragHandleTopLeft class="dragHandle top left corner" cdkDrag (cdkDragStarted)="dragStarted()" (cdkDragEnded)="dragEnded()" (cdkDragMoved)="dragMove(dragHandleTopLeft, i, $event, cdkDragRef)"></span>
<span #dragHandleTopRight class="dragHandle top right corner" cdkDrag (cdkDragStarted)="dragStarted()" (cdkDragEnded)="dragEnded()" (cdkDragMoved)="dragMove(dragHandleTopRight, i, $event, cdkDragRef)"></span>
<span #dragHandleBottomRight class="dragHandle bottom right corner" cdkDrag (cdkDragStarted)="dragStarted()" (cdkDragEnded)="dragEnded()" (cdkDragMoved)="dragMove(dragHandleBottomRight, i, $event, cdkDragRef)"></span>
<span #dragHandleBottomLeft class="dragHandle bottom left corner" cdkDrag (cdkDragStarted)="dragStarted()" (cdkDragEnded)="dragEnded()" (cdkDragMoved)="dragMove(dragHandleBottomLeft, i, $event, cdkDragRef)"></span>
<!-- window area makes sure the drag event keeps going, even when the mouse is moving over an iframe -->
<span *ngIf="dragging()" class="windowArea"></span>
  </div>
</ng-container>
