import { AnimationGroup, Animation } from "@babylonjs/core/Animations"
import { InstancedMesh, AbstractMesh, Node, Mesh, Vector3, TransformNode, Tags } from "@babylonjs/core"
import { AssetContainer } from "@babylonjs/core/assetContainer"
import { Languages } from "./languages"
import { combineLatest, merge, Observable, Subject } from "rxjs"
import { map, mergeMap, share, shareReplay, startWith, switchMap } from "rxjs/operators"
import { Color3 } from "@babylonjs/core/Maths/math.color"

export class Model {
  #oois: Ooi[] = []
  public oois$: Observable<Ooi[]>
  public parts: AbstractMesh[]
  public helpers: AbstractMesh[]
  public environment: AbstractMesh[]
  public animations: AnimationGroup[]
  public modelVariants: Node[] = []
  public baseVisibility: number = 1
  public useFreeze: boolean = false
  public assetContainer: AssetContainer
  public variants: any
  public currentVariant: string = ""

  public ooisSubject = new Subject<boolean>()

  public get oois() {
    return this.#oois
  }

  public set oois(value: Ooi[]) {
    this.#oois = value
    this.ooisSubject.next(true)
  }

  public constructor(container: AssetContainer, variants?: any) {
    this.assetContainer = container
    const nodes = container.getNodes()
    const environmentRoot = nodes.find(x => x.name == "Environment")
    const helperRoot = nodes.find(x => x.name == "Helper")
    this.environment = environmentRoot ? environmentRoot.getChildMeshes() : []
    this.helpers = helperRoot ? helperRoot.getChildMeshes() : []
    this.parts = []
    nodes.forEach(x => {
      if (x instanceof AbstractMesh && !this.helpers.includes(x) && !this.environment.includes(x)) {
        this.parts.push(x)
      }
    })
    this.animations = container.animationGroups
    //this.reset();

    //handle variants
    if (variants) {
      this.variants = variants
      for (let variant in variants) {
        let variantName = variants[variant]
        let node = nodes.find(x => x.name == variantName)
        if (node) {
          this.modelVariants.push(node)
          node.setEnabled(false)
        }
      }
    }


    this.oois$ =  this.ooisSubject.asObservable().pipe(
      switchMap(_ => merge(...this.oois.map(el => el.onChange)).pipe(
        startWith(undefined),
        map(_ => this.oois),
      )),
      shareReplay(1)
    )

    //this.oois$.subscribe(el => console.log(el))

  }

  public async showVariantByName(name: string, path: string): Promise<Indexed<Ooi>[]> {
    this.reset()
    this.currentVariant = name
    this.oois = await this.loadXRPartsMappingJSON(path, this.assetContainer)

    for (let variant of this.modelVariants) {
      if (variant.name == this.variants[name]) {
        variant.setEnabled(true)
      } else {
        variant.setEnabled(false)
      }
    }

    const parentList = this.oois
      .filter(ooi => ooi.parent == null)
      .map((ooi, index) => ({ id: index, content: ooi }))
    return parentList
  }

  public getOOICount() {
    return this.oois.length
  }

  public getOOIExplored() {
    return this.oois.filter(x => x.explored).length
  }

  public getAllOoiMeshes() {
    return this.oois.flatMap(el => el.getMeshes())
  }

  public showAllMeshes() {
    this.oois.forEach(ooi => ooi.show())
  }

  public toggleTransparent(forcetp?: boolean) {


    if (this.baseVisibility == 1) {
      this.baseVisibility = 0.1
    } else {
      this.baseVisibility = 1
    }
    if (forcetp != undefined) {
      if (forcetp === true) {
        this.baseVisibility = 0.1
      } else {
        this.baseVisibility = 1
      }
    }
    this.setToBaseVisibility()
  }

  public setOpaque() {
    this.baseVisibility = 1
    this.reset()
  }

  public findOOI(node: Node): Ooi | null {
    let result: any = null
    this.oois.forEach(x => {
      if (!result) {
        if (x.rootNodes.includes(node))
          result = x
      }
    })
    if (result)
      return result
    if (node.parent)
      return this.findOOI(node.parent)
    return null
  }

  public findOoiById(id: string) {
    return this.oois.find(el => el.id === id)
  }

  public reset() {
    this.helpers.forEach(x => this.hideMesh(x))
    this.environment.forEach(x => this.resetMesh(x))
    this.parts.forEach(x => this.resetMesh(x))
    this.animations.forEach(x => this.resetAnimation(x))
    this.oois.forEach(ooi => {
      ooi.show()
    })
  }

  public setToBaseVisibility() {
    this.environment.forEach(x => this.setMeshBaseToBaseVisibility(x))
    this.parts.forEach(x => this.setMeshBaseToBaseVisibility(x))
  }

  private setMeshBaseToBaseVisibility(mesh: AbstractMesh)
  {
    if(!(mesh instanceof InstancedMesh)){
      mesh.visibility = this.baseVisibility
    }
  }

  private resetAnimation(ani: AnimationGroup) {
    ani.stop()
    ani.reset()
  }

  private hideMesh(mesh: AbstractMesh) {
    mesh.visibility = 0
    mesh.setEnabled(false)
  }

  private resetMesh(mesh: AbstractMesh) {
    mesh.setEnabled(true)
    mesh.renderOverlay = false
    if (!(mesh instanceof InstancedMesh)) {
      mesh.visibility = this.baseVisibility
    }
  }

  public highlightMesh(mesh: AbstractMesh) {
    mesh.overlayColor = new Color3(0, 1, 1)
    mesh.overlayAlpha = 0.2
    mesh.renderOverlay = true
  }

  public selectOOI(ooi: Ooi, exclusive = true) {
    if (exclusive === true) {
      this.unselectAllOOI()
    }
    ooi.getObstructedBy()?.forEach(obstructionId => {
      const obstruction = this.oois.find(el => el.id === obstructionId)
      obstruction?.hide()
    })
    ooi.selected = true
  }

  public unselectOOI(ooi: Ooi) {
    ooi.selected = false
    const meshes = ooi.getMeshes()
    meshes.forEach(x => x.renderOverlay = false)
  }

  public unselectAllOOI() {
    this.oois.forEach(x => this.unselectOOI(x))
  }

  public async loadXRPartsMappingJSON(path: string, container: AssetContainer) {
    const response = await fetch(path)
    const json = await response.json() as ModelComponent[]
    const oois = json.map(el => {
      return new Ooi(container.getNodes(), {
        id: el.modelA6z.toString(),
        parentId: el.parentNode?.toString(),
        languages: el.language,
        obstructedBy: el.obstructedBy?.map(el => el.toString()),
        preferredCameraSettings: el.preferredCameraSettings,
        originOffset: el.originOffset ? new Vector3(el.originOffset.x, el.originOffset.y, el.originOffset.z): undefined
      })
    })
    const mappedParents = oois.map(el => {
      const parent = oois.find(ooi => ooi.id === el.parentId)
      el.parent = parent
      parent?.children.push(el)
      return el
    })
    oois.filter(el => el.parent === undefined).forEach(el => el.recalculateChildren())
    return mappedParents
  }

  /*
  public async loadXRPartsMappingCSV(path: any, container: AssetContainer) {
      //console.log(path)
      let result: Ooi[] = []
      let response = await fetch(path)
      let text = await response.text()
      //console.log(text)
      const lines = text.split("\n")
      for (let i = 1; i < lines.length; i++) {
          if (lines[i].length > 8) {
              let entry = lines[i].split(";")
              let videos = entry[8].split("#")
              let ooi = new Ooi(container.getNodes(), {
                  id: entry[1],
                  parentId: entry[0],
                  nameD: entry[2],
                  nameE: entry[3],
                  nameC: entry[4],
                  descriptionD: entry[5],
                  descriptionE: entry[6],
                  descriptionC: entry[7],
                  audiolink: entry[9],
                  imagelink: "",
                  videolinks: videos
              })
              if(entry.length > 11){
                  ooi.partId = entry[11]
              }
              result.push(ooi)
          }
      }
      this.organizeOois(result)
      return result
  }

  private organizeOois(oois: Ooi[]){
      oois.forEach(x=>{
          if(x.parentId != "" && x.parentId != "-"){
              let parent = oois.find(y=>{
                  return y.id == x.parentId
              })

              if(parent){
                  x.parent = parent
                  parent.children.push(x)
              }
          }
      })
      //console.log(oois)
  }
  */
}

export interface Indexed<T> {
  id: number;
  content: T;
}

export interface ModelComponent {
  modelA6z: string,
  parentNode: string
  language: Record<Languages, ModelComponentLanguage>
  obstructedBy?: [string | number],
  preferredCameraSettings?: CameraSettings,
  originOffset?: { x: number, y: number, z: number}
}

export interface ModelComponentLanguage {
  name: string;
  description?: string;
  audioDescription?: string;
  videos?: string[];
  audios?: string[];

}

export interface CameraSettings {
  x: number,
  y: number,
  z: number,
  alpha: number,
  beta: number,
  radius: number,
}
export interface OverlayOptions {
  alpha: number;
  color: Color3;
  visibility?: number }

export class Ooi {
  public id: string = ""
  public parent?: Ooi = undefined
  public children: Ooi[] = []
  public flattenChildren: Ooi[] = []
  public parentId: string = ""
  public languages: Partial<Record<Languages, ModelComponentLanguage>> = {}
  public partId: string = ""
  public rootNodes: Node[] = []
  public isHidden: boolean = false
  public explored: boolean = false
  public obstructedBy?: string[]
  public preferredCameraSettings?: CameraSettings
  public originOffset?: Vector3
  #selected: boolean = false
  #onChangeSubject = new Subject()
  #originNode?: TransformNode
  #offsetNodes: TransformNode[] = []

  public get onChange() {
    return this.#onChangeSubject.asObservable()
  }

  public get originNode(): TransformNode {
    if(this.#originNode === undefined || this.#originNode.isDisposed() === true) {
      const mesh = this.getMeshes()[0]
      this.#originNode = new TransformNode("origin")
      Tags.AddTagsTo(this.#originNode, "origin")
      this.#originNode.parent = mesh
      if (this.originOffset != null) {
        this.#originNode.position = this.originOffset
      }

    }
    return this.#originNode
  }

  public getOffsetNode(offset: {x:number, y:number, z:number}) {
    const newOffset = new TransformNode("mesh_offset")
    Tags.AddTagsTo(newOffset, "mesh_offset")
    newOffset.parent = this.originNode
    newOffset.position.x += offset.x
    newOffset.position.y += offset.y
    newOffset.position.z += offset.z
    this.#offsetNodes = [...this.#offsetNodes, newOffset]
    return newOffset
  }

  public constructor(nodes: Node[], init?: Partial<Ooi>) {
    Object.assign(this, init)
    this.rootNodes = nodes.filter(x => x.name.indexOf(this.id) > -1)
  }

  private setMeshOverlay(mesh: AbstractMesh, options: OverlayOptions = {
    color: new Color3(0,1,1),
    alpha: 0.2
   }) {
    mesh.overlayColor = options.color
    mesh.overlayAlpha = options.alpha
    mesh.renderOverlay = true
    mesh.visibility = options.visibility ? options.visibility : mesh.visibility
  }

  public set selected(selected : boolean) {
    if (selected === true) {
      this.getMeshes().forEach(mesh => {
        this.setMeshOverlay(mesh)
      })
    } else {
      this.getMeshes().forEach(mesh => {
        mesh.renderOverlay = false
      })
    }
    this.#selected = selected
  }

  public get selected() : boolean {
    return this.#selected
  }

  public feedback() {
    this.getMeshes().forEach(mesh => {
      mesh.renderOverlay = true
      mesh.overlayColor = new Color3(1, 0, 0)
      const animation = Animation.CreateAndStartAnimation("blink", mesh, "overlayAlpha", 60, 20,
      0.2, 0.6, 1)
      setTimeout(()=>animation?.stop(),2000)
    })

  }


  public highlight(enable: boolean, options?: OverlayOptions) {
    if (enable === true) {
      this.getMeshes().forEach(mesh => {
        this.setMeshOverlay(mesh, options)
      })
    } else if (enable === false && this.#selected === true) {
      this.selected = true
    } else {
      this.getMeshes().forEach(mesh => {
        mesh.renderOverlay = false
      })
      this.flattenChildren.forEach(el => el.selected = el.selected)
    }
  }

  public recalculateChildren(): Ooi[] {
    if (this.children.length === 0) {
      return [this]
    }
    if (this.flattenChildren.length > 0) {
      return this.flattenChildren
    }
    this.flattenChildren = this.children.flatMap(el => el.recalculateChildren())
    return this.flattenChildren
  }

  public getObstructedBy(): string[] {
    const obstructions = this.parent?.getObstructedBy() ?? []
    const allObstructions = [...(this.obstructedBy ?? []), ...obstructions]
    return allObstructions
  }

  public getPreferredCameraSettings(): CameraSettings | undefined {
    return this.preferredCameraSettings
  }

  public getOriginOffset(): Vector3 | undefined {
    return this.originOffset
  }

  public getDescription(language: string) {
    let result = this.languages[language]?.description ?? ""

    let lb = String.raw`\n`
    if (result.indexOf(lb) > -1) {
      result = result.replace(lb, "<br>")
    }
    return result
  }

  public getName(language: Languages) {
    return this.languages[language]?.name ?? ""
  }

  public getAudioDescriptionPath(language: Languages) {
    return this.languages[language]?.audioDescription ?? ""
  }

  public hide() {
    this.isHidden = true
    this.rootNodes.forEach(x => {
      x.setEnabled(false)
    })
    this.#onChangeSubject.next()
  }

  public show() {
    this.isHidden = false
    this.rootNodes.forEach(x => {
      x.setEnabled(true)
    })
    this.#onChangeSubject.next()
  }

  public toggle() {
    this.isHidden ? this.show() : this.hide()
  }

  public getMeshes() {
    let result: AbstractMesh[] = []
    this.rootNodes.forEach(x => {
      if (x instanceof AbstractMesh) {
        result.push(x)
      }
      result = result.concat(x.getChildMeshes())
    })
    return result
  }
}
