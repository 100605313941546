<header>
  <!--nav #siHeader="siHeader" [cdkTrapFocus]="siHeader.navbarExpandedOrDropdownOpen" siHeader
    class="si-header si-header-internet fixed-top" [class.bg-dark]="dark" [class.bg-light]="!dark">
    <div siHeaderContainer class="container">
      <a routerLink="/" class="si-header-brand" aria-label="Siemens logo">
        <si-logo></si-logo>
      </a>
      <button class="si-header-toggler" type="button" siHeaderToggler aria-label="Toggle navigation">
        <span class="siemens-menu"></span>
      </button>
      <div class="si-header-collapse" siHeaderCollapse>
        <div class="si-header-collapse-wrapper">
          <div class="container">
            <ul siHeaderMenu class="si-header-nav-main">
              <li siHeaderItem>
                <a routerLink="/" hidden></a>
              </li>
              li class="si-header-item" siHeaderItem>
                <a routerLink="getting-started" siHeaderLink class="si-header-link">Getting Started</a>
              </li>
            </ul>
            <ul class="si-header-nav-additional">
              <li siHeaderItem class="si-header-item">
                <a siHeaderLink href="https://code.siemens.com/simpl/simpl-siemens-brand"
                  class="si-header-link">Sources</a>
              </li>
              <li siHeaderItem class="si-header-item">
                <a siHeaderLink href="https://www.yammer.com/siemenscrosscollaboration/#/threads/inGroup?type=in_group&feedId=2951962624&view=all"
                  class="si-header-link">Yammer</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav-->
</header>
