import { Injectable } from "@angular/core"
import { share } from "rxjs/operators"
import { BehaviorSubject } from "rxjs"

export enum SpeakState{
  Speaking,
  NotSpeaking,
  Paused
}


@Injectable({
  providedIn: "root"
})
export class SpeakService {
  private synth: SpeechSynthesis
  private voices: SpeechSynthesisVoice[]
  private audio = new Audio()

  private speakingStateSource = new BehaviorSubject<SpeakState>(SpeakState.NotSpeaking)
  public speakingState = this.speakingStateSource.pipe(share())

  public constructor() {
    this.synth = window.speechSynthesis
    this.voices = this.synth.getVoices()
    console.log(this.voices.length)

   }

  public playAudioFile(path: string) {
    this.audio.src = path
    this.audio.load()
    this.audio.play()
    this.audio.onended = this.onEnd.bind(this)
    this.audio.onplay = this.onStart.bind(this)
    this.speakingStateSource.next(SpeakState.Speaking)
  }


  public play(text: string, lang: string = "de-DE") {
    if(text === "") { return }
    this.voices = this.synth.getVoices()
    const utterThis = new SpeechSynthesisUtterance(text)

    const voice = this.voices.find(voice => voice.lang === lang)
    if (!voice) { return }

    utterThis.pitch = 1
    utterThis.rate = 1
    utterThis.onend = this.onEnd.bind(this)
    utterThis.onstart = this.onStart.bind(this)
    this.synth.speak(utterThis)

  }

  private onEnd(event: any) {
    this.speakingStateSource.next(SpeakState.NotSpeaking)
  }

  private onStart(event: any) {
    this.speakingStateSource.next(SpeakState.Speaking)
  }

  public stopAudioFile() {
    this.audio.pause()
    this.audio.currentTime = 0
    this.speakingStateSource.next(SpeakState.NotSpeaking)
  }

  public stop() {
    this.synth.cancel()
  }
}
