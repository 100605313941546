import { Injectable } from "@angular/core"
import { Button3D, CylinderPanel, GUI3DManager, HolographicButton, TextBlock } from "@babylonjs/gui"
import { BabylonEngineService } from "./babylon-engine.service"
import appinfoJson from "../../assets/switchapp.json"
import { Indexed, Ooi } from "../models/model"
import { BehaviorSubject } from "rxjs"
import { Languages } from "../models/languages"
import { TranslationService } from "./translation.service"
import { TransformNode } from "@babylonjs/core/Meshes/transformNode"
import { Vector3 } from "@babylonjs/core/Maths/math.vector"

@Injectable({
  providedIn: "root"
})
export class XRManagerService {
  private anchor3dUi?: TransformNode
  private gui3dManager?: GUI3DManager
  private mainPanel3d?: CylinderPanel
  private partPanel3d?: CylinderPanel
  private animationPanel3d?: CylinderPanel
  private animationOptions: Indexed<string>[]
  private rootOois: Indexed<Ooi>[] = []
  private isArModeSource = new BehaviorSubject<boolean>(false)


  // Callbacks
  public onAnimationSelect?: (selectedAnimation: Indexed<string>) => void
  public onItemSelect?: (selected: Ooi, forceTransparent?: boolean) => void

  public constructor(
    private bsEngineService: BabylonEngineService,
    private translation: TranslationService
  ) {
    const animations: any = appinfoJson.animations

    this.animationOptions = Object.keys(animations).map((animationName, id) => (
      {
        id,
        content: animations[animationName]
      }
    ))
  }

  public get isArMode$() {
    return this.isArModeSource.asObservable()
  }

  public setMenuOoi(root: Indexed<Ooi>[]) {
    this.rootOois = [...root]
  }



  public enterARMode() {
    this.isArModeSource.next(true)
    this.bsEngineService.setEnableNodeByName("Environment", false)
    this.gui3dManager = new GUI3DManager(this.bsEngineService.scene)
    this.anchor3dUi = new TransformNode("")
    this.openMainMenu3D()
  }

  private async exitAR() {
    await this.bsEngineService.xrExperience?.baseExperience.exitXRAsync()
    this.isArModeSource.next(false)
    if (this.gui3dManager) {
      this.gui3dManager.dispose()
    }
    this.bsEngineService.setEnableMeshByName("sky_dome", true)
    this.bsEngineService.setEnableMeshByName("ground", true)
  }

  private openMainMenu3D() {
    if (this.gui3dManager && this.anchor3dUi) {
      //this.myModel?.setOpaque()
      this.gui3dManager.rootContainer.children.forEach(x => this.gui3dManager?.removeControl(x))
      this.mainPanel3d = new CylinderPanel()
      this.mainPanel3d.margin = 0.2
      this.gui3dManager.addControl(this.mainPanel3d)
      this.mainPanel3d.linkToTransformNode(this.anchor3dUi)
      this.mainPanel3d.position.y = 1
      this.mainPanel3d.position.z = -2.5
      this.mainPanel3d.position.x = -0.7
      this.mainPanel3d.rows = 1
      this.mainPanel3d.scaling = new Vector3(0.5, 0.5, 0.5)
      this.mainPanel3d.blockLayout = true
      let partsButton = new HolographicButton("")
      partsButton.text = "structure and design"
      partsButton.onPointerDownObservable.add(() => this.selectParts3D())
      let animationButton = new HolographicButton("")
      animationButton.text = "Animations"
      animationButton.onPointerDownObservable.add(() => this.openAnimations())
      let returnButton = new HolographicButton("")
      returnButton.text = "Exit AR"
      returnButton.onPointerDownObservable.add(() => {
        this.exitAR()
      })
      this.mainPanel3d.addControl(partsButton)
      this.mainPanel3d.addControl(animationButton)
      this.mainPanel3d.addControl(returnButton)
      this.mainPanel3d.blockLayout = false
    }
  }

  private openAnimations() {
    if (this.gui3dManager && this.anchor3dUi) {
      this.gui3dManager.rootContainer.children.forEach(x => this.gui3dManager?.removeControl(x))
      this.animationPanel3d = new CylinderPanel()
      this.animationPanel3d.margin = 0.2
      this.gui3dManager.addControl(this.animationPanel3d)
      this.animationPanel3d.linkToTransformNode(this.anchor3dUi)
      this.animationPanel3d.position.y = 1
      this.animationPanel3d.position.z = -2.5
      this.animationPanel3d.position.x = -0.7
      this.animationPanel3d.rows = 1
      this.animationPanel3d.scaling = new Vector3(0.5, 0.5, 0.5)
      this.animationPanel3d.blockLayout = true
      this.animationOptions.forEach(x => {
        var button = new HolographicButton("orientation")
        this.animationPanel3d?.addControl(button)
        button.text = x.content
        button.onPointerDownObservable.add(() => this.onAnimationSelect?.(x))
      })

      let exitButton = new Button3D("Exit")
      var text1 = new TextBlock()
      text1.text = "Exit"
      text1.fontWeight = "Bold"
      text1.color = "white"
      text1.fontSize = 48
      exitButton.content = text1
      exitButton.onPointerDownObservable.add(() => this.openMainMenu3D())
      this.animationPanel3d.addControl(exitButton)
      //exitButton.scaling = new Vector3(0.5,0.5,0.5)

      this.animationPanel3d.blockLayout = false
    }
  }

  private selectParts3D() {
    if (this.gui3dManager && this.anchor3dUi) {
      this.gui3dManager.rootContainer.children.forEach(x => this.gui3dManager?.removeControl(x))
      this.partPanel3d = new CylinderPanel()
      this.partPanel3d.margin = 0.1
      this.gui3dManager.addControl(this.partPanel3d)
      this.partPanel3d.linkToTransformNode(this.anchor3dUi)
      this.partPanel3d.position.y = 1
      this.partPanel3d.position.z = -2.5
      this.partPanel3d.position.x = -0.7
      //this.partPanel3d.rows = 1
      this.partPanel3d.scaling = new Vector3(0.5, 0.5, 0.5)
      this.partPanel3d.blockLayout = true
      this.rootOois.forEach(x => {
        var button = new HolographicButton("orientation")
        this.partPanel3d?.addControl(button)
        let ooi: Ooi = x.content
        button.scaling = new Vector3(0.5, 0.5, 0.5)
        button.text = ooi.getName(this.translation.language)
        button.onPointerDownObservable.add(() => this.onItemSelect?.(ooi, true))
        ooi.children.forEach(y => {
          var button = new HolographicButton("orientation")
          this.partPanel3d?.addControl(button)
          button.scaling = new Vector3(0.5, 0.5, 0.5)
          button.text = y.getName(this.translation.language)
          button.onPointerDownObservable.add(() => this.onItemSelect?.(y, true))
        })
      })
      let exitButton = new Button3D("Exit")
      var text1 = new TextBlock()
      text1.text = "Exit"
      text1.fontWeight = "Bold"
      text1.color = "white"
      text1.fontSize = 48
      exitButton.content = text1
      exitButton.onPointerDownObservable.add(() => this.openMainMenu3D())
      this.partPanel3d.addControl(exitButton)
      exitButton.scaling = new Vector3(0.5, 0.5, 0.5)
      this.partPanel3d.blockLayout = false
    }
  }


}
