import { Ooi } from "./model"
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh"
import { ScrewInfoService } from "../services/screw-info.service"
export class Screw {
  public parentOoi?: Ooi
  public name: string = ""
  public type: string = ""
  public torque: string = ""
  public glue: string = ""
  public mesh?: AbstractMesh
  public constructor(screwService: ScrewInfoService, entries: string[]) {
    if (entries.length >= 5) {
      this.name = entries[1]
      this.type = entries[2]
      this.torque = entries[3]
      this.glue = entries[4]
      this.mesh = screwService.findMesh(this.name)
      this.parentOoi = screwService.findParentOoi(entries[0])
    }
    //console.log(this)
  }
}
