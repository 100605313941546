<div class="dialog" cdkDrag cdkDragBoundary=".wrapper" #drag="cdkDrag">
  <div class="header" cdkDragHandle>
    <h2>Debug: {{__ooi?.id}}</h2>
    <span>

  </span>
  </div>
  <div class="content mx-5 mb-5">
    <p (click)="copyToClipBoard(originOffset)">
      {{originOffset}}
    </p>

  </div>

  <div class="footer m-3" (click)="copyToClipBoard(currentCameraPosition)">
    {{currentCameraPosition}}
  </div>


</div>
