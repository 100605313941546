import { ChangeDetectorRef, Component } from "@angular/core"
import { TranslateService } from "@ngx-translate/core"
import { siRouterFadeAnimation } from "@simpl/siemens-brand-ng/tools"

@Component({
  selector: "<app-root>",
  templateUrl: "./app.component.html",
  animations: [siRouterFadeAnimation]
})
export class AppComponent {
  // getRouteAnimation(outlet: RouterOutlet) {
  //   return (outlet.isActivated ? outlet.activatedRoute : null);
  // }

  public title = "configurator"

  public constructor(
    private ref: ChangeDetectorRef,
    translate: TranslateService) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.addLangs(["en", "de"])
    translate.setDefaultLang("de")

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    if (navigator.language.includes("de")) {
      translate.use("de")
    }else {
      translate.use("en")
    }
  }

  public ngAfterViewInit(){
    this.initFederateSignInButton()

  }

  public initFederateSignInButton(){
    // As amplify UI version 2 does not provide custermized federate button
    // we here replace any exist button as the context of sign in with Siemens MyID
    window.onload = function () {
      var textHTML = document.getElementsByClassName("amplify-text amplify-authenticator__federated-text")[0]
      var svgHTML = document.getElementsByClassName("amplify-icon federated-sign-in-icon")[0]
      if (textHTML != undefined)
        textHTML.innerHTML = "Sign in with SiemensMyID"
      if (svgHTML != undefined)
        svgHTML.remove()
    }
  }
}
