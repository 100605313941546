import { Component } from "@angular/core"
import { DARK } from "../../../environments/theme"

@Component({
  selector: "app-welcome",
  templateUrl: "./welcome.component.html",
  styleUrls: ["./welcome.component.scss"]
})
export class WelcomeComponent {
  public dark = DARK
}
