import { ComponentRef, Injectable, OnDestroy, ViewContainerRef } from "@angular/core"
import { Subject } from "rxjs"
import { tap } from "rxjs/operators"
import { DialogComponent } from "."

export interface XRDialog {
  title: string;
  footer?: any;
  // component hide button is not shown when "hidden" or undefined
  // "ACTIVE" or "DEACTIVATED" sets the default value for the button
  hideButton?: IHidableObject,
  closingBehavior?: "NOT_CLOSABLE" | "CLOSABLE" | "EVENT";
  dialogCloseConfirmMessage?: string
  isCollapsable?: boolean;
  align?: "left" | "center" | "right"
  mainContent: [{
    type: "text" | "radio",
    content: any
  }],
  buttons?: DialogButton[],
  onClosePayload?: object | string,
  playAudioPath?: string
}

export interface IHidableObject {
  isHidden: boolean,
  toggle: () => void
}
export interface DialogButton {
  text: string;
  payload?: object | string
  isClosing?: boolean
}

export interface InternalXRDialog extends XRDialog {
  close?: () => void;
}

export interface XRDialogEvent {
  type: string;
  payload?: unknown;
}
@Injectable({
  providedIn: "root"
})
export class DialogService implements OnDestroy {
  private componentRef?: ComponentRef<any>
  #parentRef?: ViewContainerRef


  public set parentRef(v : ViewContainerRef | undefined) {
    this.#parentRef = v
  }


  public get parentRef() : ViewContainerRef | undefined {
    return this.#parentRef
  }



  // Subject which will be new for each dialog and completes on close of dialog
  private xrDialogEventSubject!: Subject<XRDialogEvent>

  // Subject to keep around for all dialogs
  private xrDialogServiceEventSubject = new Subject<XRDialogEvent>()
  public xrDialogServiceEvents$ = this.xrDialogServiceEventSubject.asObservable()

  public constructor() {

   }

   public show(dialog: XRDialog, keep = true) {
    // Set default value for closing behavior
    dialog.closingBehavior = dialog.closingBehavior == null ? "CLOSABLE" : dialog.closingBehavior
    if (this.componentRef && keep === false) {
      this.close()
    }

    if (this.componentRef == null) {

      if (this.parentRef) {
        this.componentRef = this.parentRef.createComponent<DialogComponent>(DialogComponent)
      } else {
        throw new Error("Set parentRef before using the dialog")

      }
    }

    if(this.xrDialogEventSubject) {
      this.xrDialogEventSubject.complete()
    }
    this.xrDialogEventSubject = new Subject<XRDialogEvent>()
    this.xrDialogEventSubject.pipe(
      tap(event => this.xrDialogServiceEventSubject.next(event))
    ).subscribe()
    this.componentRef.instance.xrDialogEventSubject = this.xrDialogEventSubject
    ;(dialog as InternalXRDialog).close = () => this.close()
    this.componentRef.instance.dialogContent = dialog
    return this.xrDialogEventSubject.asObservable()
   }

   public close() {
    this.xrDialogEventSubject.complete()
    if (this.componentRef) {
      this.componentRef.destroy()
      this.componentRef = undefined
    }
   }

   public ngOnDestroy(): void {
    this.close()
  }

}
