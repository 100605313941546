import { Injectable, inject, signal } from "@angular/core"
import { EMPTY, Observable, Subject } from "rxjs"
import { filter, tap, first, takeWhile, scan, last, share, map } from "rxjs/operators"
import { interactions } from "../utils/interactions"
import { Action, DialogButtonInteraction, GuidedDialog, GuidedExperience, Interaction, interpolateString, OrderedTask, Step } from "../utils/utils"
import { BabylonjsInteractionService as BabylonJSInteractionService } from "./babylonjs-interaction.service"
import { DialogService } from "../components/dialog"
import { TranslateService } from "@ngx-translate/core"
import { ModelManagementService } from "./model-management.service"
import { Vector3 } from "@babylonjs/core/Maths/math.vector"
import { WindowsService } from "src/app/components/windows"
import { PdfComponent } from "../components/pdf/pdf.component"
import { ImageComponent } from "../components/image/image.component"
import { reverse } from "dns"

interface GuidedInteraction {
  guidedExperience?: GuidedExperience
  step: number
  prevStep?: number
  payload?: any
}

export interface GuidedManual {
  step: number,
  interaction: Interaction
}

@Injectable({
  providedIn: "root"
})
export class GuidedInteractionServiceService {

  private windowService = inject(WindowsService)

  private dialogConfirmMessage = "TRAININGS.CANCEL_TRAINING"

  private guidedInteraction = interactions

  #isInTraining = false

  public get isInTraining() {
    return this.#isInTraining
  }

  public guidedInteractionSet = signal<GuidedManual|undefined>(undefined)
  private actionHistory: (Action)[] = []

  private guidedInteractionSource = new Subject<GuidedInteraction>()
  private guidedInteraction$ = this.guidedInteractionSource.asObservable().pipe(
    takeWhile(el => el?.payload !== "Done" || this.#isInTraining === true),
    scan((acc, curr) => {
      if (curr.guidedExperience != null && acc.guidedExperience !== curr.guidedExperience) {
        return {
          guidedExperience: curr.guidedExperience,
          step: curr.step,
          prevStep: acc.step
        }
      } else {
        return {
          ...acc,
          ...curr,
          prevStep: acc.step
        }
      }
    }, {} as GuidedInteraction),
    //distinctUntilKeyChanged("step"),
    tap(async el => {
      if (!el.guidedExperience) { return }
      const currentStep = el.guidedExperience.steps.find(step => el.step === step.id)
      const stepDiff = el.step - (el.prevStep ?? 0)
      if (stepDiff < 0) {
        await this.reverseActions(el.step, el.guidedExperience)
        // Go back the order of actions
      }
      else if (stepDiff > 1) {
        let gapStepNr = (el.prevStep ?? 0) + 1
        while (gapStepNr < el.step) {
          const gapStep =  el.guidedExperience.steps.find(step => step.id === gapStepNr)
          if (gapStep) {
            this.actionHistory.push({type: "STEP", value: gapStepNr})
            this.executeActions(gapStep.actions ?? [])
          }
          gapStepNr++
        }
      }
      if (currentStep?.actions) {
        this.executeActions(currentStep.actions)
      }
      if(el.guidedExperience.type === "MANUAL") {
        this.guidedInteractionSet.set({ step: el.step, interaction: el.guidedExperience })
        return
      }
      switch (currentStep?.type) {
        case "GUIDED_DIALOG": {
            await this.guidedDialog(currentStep)
          }
          break
        case "ORDERED_TASK": {
            await this.orderedTask(currentStep)
          }
          break
        default:
          break
      }
    }),
    share()

  )

  public constructor(
    private dialogService: DialogService,
    private bjsInteractionService: BabylonJSInteractionService,
    private translate: TranslateService,
    private modelManagementService: ModelManagementService) { }


  private async guidedDialog(guidedDialogStep: GuidedDialog) {

    const result = await this.dialogService.show({
      mainContent: [{
        type: "text",
        content: guidedDialogStep.text,
      }],
      playAudioPath: guidedDialogStep.audio,
      title: guidedDialogStep.title,
      isCollapsable: true,
      dialogCloseConfirmMessage: this.dialogConfirmMessage,
      align: "right",
      buttons: (guidedDialogStep.interactions?.filter(el => el.type === "DIALOG_BUTTON_PRESS") as DialogButtonInteraction[])
        .map((el) => ({ text: el.text, payload: el.actions, isClosing: el.isClosing })) ?? []
    }).pipe(filter(el => el.type == "BUTTON_PRESS"), first()).toPromise()
    this.executeActions(result.payload as Action[])
  }


  private async orderedTask(orderedTaskStep: OrderedTask) {

    this.dialogService.show({
      mainContent: [{
        type: "text",
        content: orderedTaskStep.text ?? "",
      }],
      title: orderedTaskStep.title ?? "",
      playAudioPath: orderedTaskStep.audio,
      isCollapsable: true,
      dialogCloseConfirmMessage: this.dialogConfirmMessage,
      align: "right",
      buttons: (orderedTaskStep.interactions?.filter(el => el.type === "DIALOG_BUTTON_PRESS") as DialogButtonInteraction[])
      .map((el) => ({ text: el.text, payload: el.actions, isClosing: el.isClosing })) ?? []
    }).pipe(filter(el => el.type == "BUTTON_PRESS"))
    .subscribe(el => this.executeActions(el.payload as Action[]))

    let orderedIteration = 0
    while (orderedIteration < (orderedTaskStep.selection?.length ?? 0)) {
      if (orderedTaskStep.selection[orderedIteration].actions != null) {
        this.executeActions(orderedTaskStep.selection[orderedIteration].actions!)
      }
      const result = await this.bjsInteractionService.getSinglePickedOoi().pipe(
        map(el => this.modelManagementService.findOOI(el)),
        filter(el => el != null),
        filter(el => !!el), first()).toPromise()
      // make sure your still in training
      if(this.#isInTraining === false) {
        return
      }
      if (result?.id === orderedTaskStep.selection?.[orderedIteration].part) {
        orderedIteration++
        this.modelManagementService.selectOOI(result!, false)
      } else {
        this.modelManagementService.feedback(result!)
        //this.modelManagementService.unselectAllOOI()
        //orderedIteration = 0
      }
    }
    this.executeActions(orderedTaskStep.succuss)
  }

  public async reverseActions(tillStep: number, guided: GuidedExperience) {
    const showHidePartSteps = guided.steps.filter(el => el.id < tillStep).flatMap(el => el.actions).filter(el => el != null && (el.type === "SHOW_ALL" || el.type === "SHOW_PART" || el.type === "HIDE_PART")) as Action[]
    await this.executeActions(showHidePartSteps, true)


    const idx = this.actionHistory.findIndex(el => el.type === "STEP" && el.value === tillStep)
    const actionToReverse = this.actionHistory.splice(idx+1).reverse().filter(el => !(el.type === "SHOW_ALL" || el.type === "SHOW_PART" || el.type === "HIDE_PART")).map(el => ({...el, reverse: true}))
    await this.executeActions(actionToReverse, true)
  }

  public async executeActions(actions: Action[], noHistory?: boolean) {
    if(!(noHistory === true)) {
      this.actionHistory.push(...actions)
    }
    for(let i = 0; i< actions.length; i++) {
      await this.executeAction(actions[i])
    }
  }

  private delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  private async executeAction(action: Action, stateParams?: object) {
    if(action.delay) {
      await this.delay(action.delay)
    }
    switch (action.type) {
      case "HIDE_PART":
        {
          const ooi = this.modelManagementService.findOoiById(action.value)
          if(action.reverse) {
            ooi?.show()
          } else {
            ooi?.hide()
          }
        }
        break
      case "SHOW_PART":
        {
          const ooi = this.modelManagementService.findOoiById(action.value)
          if(action.reverse) {
            ooi?.hide()
          } else {
            ooi?.show()
          }

        }
        break
      case "SHOW_ALL":
          {
            if(action.reverse) {return}
            this.modelManagementService.showAll()
          }
          break
      case "HIGHLIGHT_PART":
        {
          const ooi = this.modelManagementService.findOoiById(action.value)
          if(action.reverse) {
            ooi?.highlight(false, action.options)
          } else {
            ooi?.highlight(true, action.options)
          }
        }
        break
      case "ARC_CAMERA":
        {
          if(action.reverse) {return}
          const {x, y, z, alpha, beta, radius, time } = action
          this.bjsInteractionService.moveArcRotateCamera(new Vector3(x, y, z), radius, alpha, beta, time)
        }
        break
      case "STEP":
        {
          if(action.reverse) {return}
          this.guidedInteractionSource.next({
            step: action.value,
            payload: action.payload
          })
        }
        break
      case "ANIMATION":
        {
          if (((action.action == null || action.action === "PLAY") && action.reverse == null) || (action.action === "REVERSE" && action.reverse)) {
            if (typeof action.value === "string") {
              this.modelManagementService.playAnimationByName(action.value)
            } else {
              this.modelManagementService.playAnimationByID(action.value)
            }
          } else if (action.action === "RESET" && action.reverse == null) {
            if (typeof action.value === "string") {
              this.modelManagementService.resetAnimationByName(action.value)
            }
          } else if (action.action === "REVERSE" || (action.action === "PLAY" && action.reverse)) {
            if (typeof action.value === "string") {
              this.modelManagementService.playAnimationByName(action.value, "BACKWARD")
            }
          }

        }
        break
      case "LOCK":
        {
          this.#isInTraining = action.value
        }
        break
      case "SHOW_DIALOG":
        {
          if(action.reverse) {return}
          this.dialogService.show({
            mainContent: [{
              type: "text",
              content: action.text
            }],
            playAudioPath: action.audio ? action.audio : undefined,
            align: action.align,
            title: action.title,
            onClosePayload: action.onLeaveActions,
            buttons: (action.interactions?.filter(el => el.type === "DIALOG_BUTTON_PRESS") as DialogButtonInteraction[])
            ?.map((el) => ({ text: el.text, payload: el.actions, isClosing: el.isClosing })) ?? []
          }).pipe(filter(el => el.type == "BUTTON_PRESS"))
          .subscribe({
            next: (el) => { this.executeActions(el.payload as Action[])},
            complete: () => {
              action.onLeaveActions ? this.executeActions(action.onLeaveActions): undefined
            }
          })
        }
        break
      case "X_RAY_MODE":
        {
          if(action.reverse) {
            this.modelManagementService.toggleTransparent(!action.value)
          } else {
            this.modelManagementService.toggleTransparent(action.value)
          }
        }
        break
      case "UNSELECT_ALL":
        {
          this.modelManagementService.unselectAllOOI()
        }
        break
      case "LABEL_PART":
        {
          if(action.reverse) {return}
          const ooi = this.modelManagementService.findOoiById(action.value)
          const name = ooi?.getName(this.translate.currentLang)
          const params = { name, ...stateParams }
          const text = this.translate.instant(action.text ?? action.value, params) as string
          const interpolate = interpolateString(text, params)

          ooi ? await this.bjsInteractionService.show3dLabel(ooi, interpolate, action.options) : null
        }
        break
      case "CLEAR_LABEL":
        {
          //const ooi = this.model?.findOoiById(action.value)
          //ooi ? this.bjsInteractionService.show3dLabel(ooi, action.text) : null
        }
        break
      case "CLEAR_ALL_LABEL":
        {
          this.bjsInteractionService.removeAllControls()
        }
        break
      case "WAIT_FOR":
        {
          if(action.reverse) {return}
          await new Promise(resolve => setTimeout(resolve, action.value * 1000))
        }
        break
      case "OPEN_WINDOW":
        {
          if(action.reverse) {return}
          switch (action.windowType) {
            case "PDF":
              {
                if (action.value)
                  this.windowService.open(
                    PdfComponent,
                    { url: action.value},
                    {
                      title: action.title,
                      startPosition: action.position,
                      size: action.size
                    } )
              }

              break
            case "IMAGE":
              {
                if (action.value)
                  this.windowService.open(ImageComponent, { url: action.value}, { title: action.title } )
              }

              break

            default:
              break
          }
        }
          break
      default:
        break
    }
  }

  public startInteraction(name: string) {
    const interaction = this.guidedInteraction[name]

    if (interaction?.type === "SIMPLE") {
      if (interaction?.actions) {
        this.executeActions(interaction.actions)
      }
      return EMPTY
    }

    if (interaction?.onStart) {
      this.executeActions(interaction.onStart)
    }

    return new Observable((observer => {
      const subscription = this.guidedInteraction$.pipe(
        last(),
        tap(_ => {
          this.#isInTraining = false
          this.guidedInteractionSet.set(undefined)
          this.actionHistory = []
        })
      ).subscribe({
        complete() {
          observer.next()
          observer.complete()
        }
      })
      this.guidedInteractionSource.next({
        guidedExperience: interaction,
        step: 0
      })
      this.executeActions([{ type: "STEP", value: 1}])

      // Listen for a close event on any of the dialogs shows in the interaction
      const dialogServiceSubscription = this.dialogService.xrDialogServiceEvents$.pipe(filter(event => event.type === "CLOSE")).subscribe(_ => {
        this.#isInTraining = false
        if (interaction?.onFinished) {
          this.executeActions(interaction.onFinished)
        }
        observer.next()
        observer.complete()
      })

      return () => {
        subscription.unsubscribe()
        dialogServiceSubscription.unsubscribe()
      }
    }))


  }

}
