import { Injectable, Signal, Type, computed, signal } from "@angular/core"
import { ComponentType } from "@angular/cdk/portal"

interface DynamicWindow {
  id: number,
  comp: Type<any>,
  inputs: Record<string, unknown>,
  title?: string
  startPosition: { x: number; y: number}
  size: {x: number, y: number}
}

export interface WindowOptions {
  title?: string
  startPosition?: {x: number, y: number}
  size?: {x: number, y: number}
}

@Injectable({
  providedIn: 'root'
})
export class WindowsService {

  private windowsSignal = signal<DynamicWindow[]>([
   /*
   // Testdata
    {
      id: 0,
      comp: PdfComponent,
      inputs: { url: "/assets/Repair Instruction SICHARGE D.pdf"},
      startPosition: {x: 400, y: 100}
    }
    */
  ])

  private nextId = computed(() => {
    const windows = this.windowsSignal()
    if (windows.length === 0) { return 0}
    return Math.max(...windows.map(el => el.id)) + 1
  })

  protected get dragStartPosition() {
    return {x: this.getRndInteger(100,500), y: this.getRndInteger(100,500)}
  }

  public get windows(): Signal<DynamicWindow[]> {
    return this.windowsSignal.asReadonly()
  }

  public closeWindow(id: number) {
    this.windowsSignal.update(windows => windows.filter(el => el.id !== id))
  }

  /**
   * Opens a component in o movable and resizable window
   * @param component component to open
   * @param inputs imparts the component takes
   * @param startPosition where the component window is placed based on the top-left corner of the window. If undefined than random.
   * @returns
   */
  public open<C= unknown>(component: ComponentType<C>, inputs: Record<string, unknown>, options?: WindowOptions) {
    const newId = this.nextId() + 1
    this.windowsSignal.update(windows => {
      return [...windows,
        {
          id: this.nextId(),
          comp: component,
          inputs,
          title: options?.title,
          startPosition: options?.startPosition ? options.startPosition : this.dragStartPosition,
          size: options?.size ? options.size : { x: 300, y: 300 }
        }]
    })
    return newId

  }

  private getRndInteger(min: number, max: number) {
    return Math.floor(Math.random() * (max - min) ) + min
  }
}
