<div class="container">
  <div class="dialog" id="target-{{item.id}}" [ngClass]="{active: item.id === active()}" *ngFor="let item of (steps()).steps; index as i" (click)="goToStep($event,item.id)">
    <div class="title"><h2>{{item.title}}</h2></div>
    <div class="content">
      <ngx-si-markdown [preventExternalLinks]="true" (mdEvent)="mdEvent($event, $event)" class="text" [text]="item.text | translate"></ngx-si-markdown>
    </div>
    <div class="footer">
      <ng-container *ngFor="let button of item.interactions">
        <button class="btn btn-link" *ngIf="button.type ==='DIALOG_BUTTON_PRESS'" (click)="doInteraction($event,button.actions)">{{$any(button).text | translate}}</button>
      </ng-container>
    </div>
  </div>

</div>
