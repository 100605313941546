import { NgModule } from "@angular/core"
import { RouterModule, Routes } from "@angular/router"

import { GettingStartedComponent } from "./pages/getting-started/getting-started.component"
import { InspectorMainComponent } from "./pages/inspector-main/inspector-main.component"
//import { WelcomeComponent } from './pages/welcome/welcome.component';

const routes: Routes = [
  { path: "", component: InspectorMainComponent},
  { path: "getting-started", component: GettingStartedComponent, data: { title: "Geting started" } }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
