import { Injectable } from "@angular/core"
import { Scene, Engine, Color4, ArcRotateCamera, Vector3, SceneLoader, CubeTexture, SSAO2RenderingPipeline, AbstractMesh, IPointerEvent, Matrix, WebXRDefaultExperience, Mesh, ActionManager, ExecuteCodeAction, HighlightLayer, Color3, EasingFunction, QuarticEase, Texture, StandardMaterial, MeshBuilder, ISceneLoaderProgressEvent } from "@babylonjs/core"
import { GLTFFileLoader, GLTFLoaderAnimationStartMode } from "@babylonjs/loaders"
import { Subject } from "rxjs"
import { Model, Ooi } from "src/app/models/model"
import { WeichenAppAnimationService } from "src/app/services/weichenapp-animation.service"
import { AnimationService } from "../models"
import { Rectangle } from "@babylonjs/gui/2D/controls/rectangle"
import { Control } from "@babylonjs/gui/2D/controls/control"
import { TextBlock } from "@babylonjs/gui/2D/controls/textBlock"
import { AdvancedDynamicTexture } from "@babylonjs/gui/2D/advancedDynamicTexture"

export enum PickMode {
  SinglePick,
  MultiPick
}

export interface HitDetection {
  type: PickMode,
  picked: AbstractMesh[]
}

@Injectable({
  providedIn: "root"
})
export class BabylonEngineService {
  private useFreeze: boolean = false
  #speedRatio = 2
  public scene!: Scene
  public engine!: Engine
  public camera?: ArcRotateCamera
  public ssao!: SSAO2RenderingPipeline
  public ambientOcc = false
  public xrExperience?: WebXRDefaultExperience
  private hl!: HighlightLayer

  public variants: any
  public variantOptions: any[] = []

  public myModel?: Model

  private pickMode = PickMode.SinglePick
  private hitSource = new Subject<HitDetection>()
  public hit$ = this.hitSource.asObservable()
  private speedRatioSubject = new Subject<number>()
  public speedRatio$ = this.speedRatioSubject.asObservable()


  public advancedTexture!: AdvancedDynamicTexture
  private statusBox!: Rectangle
  private statusLabel!: TextBlock


  public get speedRatio() : number {
    return this.#speedRatio
  }

  public set speedRatio(speed : number) {
    this.#speedRatio = speed
    this.speedRatioSubject.next(speed)
  }

  public constructor(
  ) { }

  public async createScene(canvas: HTMLCanvasElement, xrMode: XRSessionMode = "immersive-vr") {
    if (this.scene) {
      this.scene.dispose()
    }

    canvas.style.height = "100vh"
    canvas.style.width = "100%"
    this.engine = new Engine(canvas, true) // Generate the BABYLON 3D engine

    this.scene = new Scene(this.engine)
    this.scene.clearColor = new Color4(0, 0, 0, 1.0)

    this.advancedTexture = AdvancedDynamicTexture.CreateFullscreenUI("UI")
    this.addLoadingLabel()
    this.advancedTexture.layer!.layerMask = 2

    this.camera = new ArcRotateCamera("Camera", -1.57, 1.21, 5, new Vector3(0, 0, 0), this.scene)
    this.camera.wheelPrecision = 500
    this.camera.minZ = 0
    this.camera.setTarget(Vector3.Zero())
    this.camera.attachControl(canvas, false)

    let gltfLoader = new GLTFFileLoader()
    gltfLoader.animationStartMode = GLTFLoaderAnimationStartMode.NONE
    SceneLoader.RegisterPlugin(gltfLoader)

    //hdr
    const hdrTexture = CubeTexture.CreateFromPrefilteredData("/assets/environment.env", this.scene)
    this.scene.environmentTexture = hdrTexture
    // \hdr
    //ssao
    var ssaoRatio = {
      ssaoRatio: 0.5, // Ratio of the SSAO post-process, in a lower resolution
      blurRatio: 0.5  // Ratio of the combine post-process (combines the SSAO and the scene)
    }
    this.ssao = new SSAO2RenderingPipeline("ssao", this.scene, ssaoRatio)
    this.ssao.radius = 1
    this.ssao.totalStrength = 1
    this.ssao.expensiveBlur = true
    this.ssao.samples = 16
    this.ssao.maxZ = 50
    if (this.ambientOcc) {
      this.scene.postProcessRenderPipelineManager.attachCamerasToRenderPipeline("ssao", this.camera)
    }

    this.hl = new HighlightLayer("hl1", this.scene, {
      isStroke: true,
      blurTextureSizeRatio: 3

    })

    const skybox = MeshBuilder.CreateBox("skyBox", { size: 1000.0 }, this.scene)
    const skyboxMaterial = new StandardMaterial("skyBox", this.scene)
    skyboxMaterial.backFaceCulling = false
    skyboxMaterial.reflectionTexture = new CubeTexture("textures/skybox", this.scene)
    skyboxMaterial.reflectionTexture.coordinatesMode = Texture.SKYBOX_MODE
    skyboxMaterial.diffuseColor = new Color3(0, 0, 0)
    skyboxMaterial.specularColor = new Color3(0, 0, 0)
    skybox.material = skyboxMaterial

    this.scene.onPointerDown = (event: IPointerEvent) => {
      //Guard
      if (event.button !== 0) { return }

      const ray = this.scene.createPickingRay(this.scene.pointerX, this.scene.pointerY, Matrix.Identity(), this.camera!)

      switch (this.pickMode) {
        case PickMode.SinglePick:
          const hit = this.scene.pickWithRay(ray)
          const pickedMash = hit?.pickedMesh
          if (pickedMash) {
            this.hitSource.next({
              type: this.pickMode,
              picked: [pickedMash]

            })
          }
          break
        case PickMode.MultiPick:
          var hits = this.scene.multiPickWithRay(ray, x => true)
          if (hits) {
            const picked = hits
              .map(el => el.pickedMesh != null ? el.pickedMesh : undefined)
              .filter((mesh): mesh is AbstractMesh => !!mesh)

            if(picked.length > 0)
            {
              this.hitSource.next({
                type: this.pickMode,
                picked: picked
            })}
          }
          break

        default:
          break
      }
    }

    // XR Stuff
    this.xrExperience = await this.scene.createDefaultXRExperienceAsync({
      uiOptions: {
        sessionMode: xrMode
      }
    })

    this.startTheEngine()
    return this.scene
  }

  private glbLoadingProgress(name: string, event: ISceneLoaderProgressEvent) {
    if (event.total > 1000000) {
      this.statusBox.isVisible = true
    }
    console.log(`File ${name} Total: ${event.total} Loaded: ${event.loaded}`)
    const percent = (event.loaded * 100 / event.total).toFixed(0)
    this.statusLabel.text = `${percent}% heruntergeladen`
  }

  public async loadAssets(model: string) {
    //load main model
    const container = await SceneLoader.LoadAssetContainerAsync("", model, this.scene, (event) => this.glbLoadingProgress(model, event), ".glb")

    this.statusBox.isVisible = false
    return container
  }

  public addMeshesToHover(oois: Ooi[]) {
    oois.forEach(ooi => {
      ooi.getMeshes().forEach(mesh => {
        mesh.actionManager = new ActionManager(this.scene)
        const easingFunction = new QuarticEase()
        mesh.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, (ev) => {
          ooi.highlight(true, { alpha: 0.3, color: new Color3(0.3, 1, 1)})
        }))
        mesh.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOutTrigger, (ev) => {
          ooi.highlight(false)
        }))
      })

    })
  }

  private startTheEngine() {
    let freshRender = true
    this.engine.runRenderLoop(() => {
      this.scene.render()
      if (freshRender) {
        this.engine.resize()
        freshRender = false
      }
    })
    window.addEventListener("resize", () => this.engine.resize())
  }

  public stopAllAnimations() {
    this.scene.stopAllAnimations()
  }


  // Helper

  public showDebug(enable: boolean) {
    this.scene.debugLayer.show({
      embedMode: enable
    })
  }

  public updateWithFreeze(call: () => void) {
    if (this.useFreeze) {
      this.scene.freezeActiveMeshes(true)
      call()
      this.scene.unfreezeActiveMeshes()
    } else {
      call()
    }
  }

  private hideMesh(mesh: AbstractMesh) {
    this.updateWithFreeze(() => {
      mesh.visibility = 0
      mesh.setEnabled(false)
    })
  }

  public setEnableMeshByName(name: string, enable: boolean) {
    const mesh = this.scene.getMeshByName(name)
    if (mesh) {
      mesh.setEnabled(enable)
    }
  }

  public setEnableNodeByName(name: string, enable: boolean) {
    const node = this.scene.getNodeByName(name)
    if (node) {
      node.setEnabled(enable)
    }
  }

  private optimizeScene() {
    let root = this.scene.getNodeByName("__root__")
    if (root) {
      root.getChildren().forEach(x => x.parent = null)
      root.dispose()
    }
    this.scene.meshes.forEach(x => {
      x.freezeWorldMatrix()
      x.alwaysSelectAsActiveMesh = true
      //x.cullingStrategy = AbstractMesh.CULLINGSTRATEGY_OPTIMISTIC_INCLUSION_THEN_BSPHERE_ONLY
    }
    )
    this.scene.freezeActiveMeshes(true)
    this.scene.materials.forEach(x => x.freeze())
  }

  private addLoadingLabel() {
    const rect1 = new Rectangle()
    rect1.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_CENTER
    rect1.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP
    rect1.width = 0.2
    rect1.height = "30px"
    rect1.cornerRadius = 5
    rect1.color = "Black"
    rect1.thickness = 1
    rect1.background = "white"
    rect1.top = "110px"
    rect1.left = "0px"
    this.advancedTexture.addControl(rect1)

    this.statusLabel = new TextBlock()
    this.statusLabel.text = "100% heruntergeladen"
    rect1.addControl(this.statusLabel)
    rect1.isVisible = false
    this.statusBox = rect1
  }
}

