<section [class.dark]="dark" [class.light]="!dark">
  <div class="container">
    <div class="row">
      <div class="col col-md-9">
        <h2>Welcome to the SiMPL Siemens brand example application!</h2>
        <p>
          This is the SiMPL Web app starter with the Siemens brand theme and the
          related Angular components.
        </p>
      </div>
    </div>
  </div>
</section>
