import {MissingTranslationHandler, MissingTranslationHandlerParams} from "@ngx-translate/core"
import { Languages } from "../models/languages"
import { translations } from "./translations"

export class TrainingTranslationHandler implements MissingTranslationHandler {

    private translations = translations

    public handle(params: MissingTranslationHandlerParams) {
      const currentLanguage = params.translateService.currentLang as Languages
        return this.translations[params.key] ?.[currentLanguage] ?? params.key
    }
}
