import { Component, computed, inject, effect } from "@angular/core"
import { LinkClicked } from "@mo-ti-spa/ngx-si-markdown"
import { GuidedInteractionServiceService } from "src/app/services/guided-interaction-service.service"

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent {

  private interactions = inject(GuidedInteractionServiceService)

  private e = effect(()=> {
    this.moveIntoView(this.active())
  })

  public active = computed(() => this.steps().active)

  public steps = computed(() => {
    const currentState = this.interactions.guidedInteractionSet()
    if (currentState == null || currentState.interaction.type !== "MANUAL") { return { steps: [], active: 0} }
    return {
      steps: currentState.interaction.steps.filter(step => step.type === "GUIDED_DIALOG"),
      active: currentState.step
    }
  })

  public moveIntoView(i: number, event?: Event) {
    event?.stopPropagation()
    setTimeout(() => {
      document?.getElementById(`target-${i}`)?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest"
        })
    },0)


  }

  public doInteraction(event: Event, interactions: any) {
    event?.stopPropagation()
    this.interactions.executeActions(interactions)
  }

  public goToStep(event: Event, step: number) {
    event?.stopPropagation()
    this.interactions.executeActions([{ type: "STEP", value: step }])
  }

  public mdEvent(el:  LinkClicked, ev: any) {
    const event = el.link.slice(1)
    const steps =  this.steps()
    const step = steps.steps.find(el => el.id === steps.active)
    const actions = step?.interactions?.find(el => el.type === "DIALOG_EVENT" && el.event === event)?.actions
    if(actions == null) { return }
    this.interactions.executeActions(actions)
  }
}
