import { Languages } from "../models/languages"
import { translations_animations } from "./translations_animations"
import { translations_trainings } from "./translations_trainings"

export type Translation = Record<Languages, string>
export type Translations = Record<string, Partial<Translation>>

export const translations: Translations = {
  ...translations_animations,
  ...translations_trainings,
  "TRAINING_NOT_YET_IMPLEMENTED.TITLE": {
    "de": "Training noch nicht implementiert",
    "en": "Training not yet implmented"
  },
  "TRAINING_NOT_YET_IMPLEMENTED.TEXT": {
    "de": "Dieses Trainng wurde noch nicht implmentiert, versuchen sie eines der anderen Trainings. :)",
    "en": "Training has not yet been implemented, please try a different one. ;)"
  },
  "WELCOME_TRAINING": {
    "de": "Herzlich willkommen zur interaktiven Reperaturanleitung für die SICHARGE D-Serie”\n\n\nDie Produkte der SICHARGE D-Serie sind ausschließlich zum Laden von Elektrofahrzeugen bestimmt. Bitte beachten Sie mitgeltende Hinweise und Vorschriften der Fahrzeughersteller.\n\n\n Das vorliegende Dokument beschreibt ausschließlich den elektromechanischen Austausch und Einbau von Komponenten. Lesen Sie die Betriebsanleitung und Sicherheitshinweise aufmerksam durch, ehe Sie mit den Arbeiten beginnen. \n\nBevor Sie mit dem Training starten, machen Sie sich mit der Navigation vertraut. Klicken Sie dazu bitte auf \"Navigation lernen\"",
    "en": "A warm welcome to the web-based training \"Basics of the Switchguard ELS710 point machine\".\n\n\nThe products of the SICHARGE D series are intended exclusively for charging electric vehicles.\n\n\n This document only describes the electromechanical replacement and assembly of components. Before starting work, read the operating instruction and safety notes carefully.\n\nBefore you start training, please familiarize yourself with the navigation. To do so, please click the button \"learn navigation\"."
  },
  "FINISH_TUTORIAL": {
    "de": "Wenn Sie mit der Navigation vertraut sind, können Sie nun beginnen.\n\nIn dem Menü Komponenten können Sie mehr über einzelne Teile des Chargers erfahren. \n\nUnter dem Punkt Anleitungen, finden sie verschiedene Anleitungen um Komponenten zu tauschen oder zu reparieren.",
    "en": "If you are familiar with the navigation, you can now start.\n\n In the menu components you can learn more about the different components of the charger. \n\nUnder \"Manuals\" you find different descriptions on how to repair or replace a component."
  },
  "TRAINING.START": {
    "de": "Öffnen /Schließen durch Auswählen und Anklicken (Mauszeiger) der Komponente, die Sie kennenlernen möchten",
    "en": "Open / Close to choose the part you would like to get to know."
  },
  "TRAINING.COMPONENT": {
    "de": "Die aktuell ausgewählte Teilkomponente oder Komponentengruppe wird angezeigt.",
    "en": "Currently selected component / component group"
  },
  "TRAINING.INTRO.TITLE": {
    "de": "Einführung Anleitungen",
    "en": "Introduction Manuals"
  },
  "TRAINING.INTRO.TEXT": {
    "de": "Hier haben Sie die Möglichkeit interaktiv zu erfahren, wie Sie einzelne Teile des Chargers reparieren oder ersetzen können. \n\n\nIm Rahmen des MVP steht aktuell nur eine Anleitung zur Verfügung.",
    "en": "You now have the opportunity to learn in an interactive way to repair or replace a part of the charger.\n\n\nIn the context of the MVP there is only just on manual available."
  },
  "TRAINING.INTRO.AUDIO": {
    "de": "VR_ELS710_29_DE.mp3",
    "en": "VR_ELS710_29_ENG.mp3"
  },

  "COMPONENTS.INTRO.TITLE": {
    "de": "Einführung Komponenten",
    "en": "Introduction components"
  },
  "COMPONENTS.INTRO.TEXT": {
    "de": "Unter dem Menü “Komponenten” können Sie den konstruktiven Aufbau des Chargers und seine wichtigsten Komponenten kennenlernen.\n\nEin grünes Aufleuchten der Komponente am Modell zeigt an, wo Sie klicken müssen, um weitere Informationen zu erhalten.\n\nAllgemeine Hinweise zu den Komponenten:\n\n###### Ziel\nKennenlernen der einzelnen Komponenten des Chargers\n\n###### Reihenfolge\nFreie Entscheidung in der Auswahl der Komponenten",
    "en": "Under the \"Components\" menu, you can get to know the design structure of the charger and its most important components.\n\nA green light up of the component on the model indicates where you must click to get further information.\n\nGeneral notes on the components:\n\n###### Purpose\nGetting to know the individual components of the turnout drive.\n\n###### Order\nFree decision in the selection of the components"
  },
  "COMPONENTS.INTRO.AUDIO": {
    "de": "VR_ELS710_05_DE.mp3",
    "en": "VR_ELS710_05_ENG.mp3"
  },

  "TRAINING.THROW_BAR_EXTEND_RETRACT.MENU_ITEM": {
    "de": "3. Ausfahren und einfahren des Stellschiebers",
    "en": "3. Extending and Retracting the throw bar"

  },
  "TRAINING.CLOSING_SECURING_STATE.MENU_ITEM": {
    "de": "Einheit 3: Verschließen / Sichern der Endlage",
    "en": "Unit 3: Closing / Securing the end state"
  },
  "TRAINING.SUPERVISING_END_STATE": {
    "en": "Unit 4: Supervising end state",
    "de": "Einheit 4: Überwachen der Endlagen"
  },
  "SIEMENS_HIDE": {
    "de": "Ändern der Sichtbarkeit der Teilkomponente oder Komponentengruppe kann hier gewählt werden.",
    "en": "Toggle between visible of a component or component group."
  },
  "AUDIO_PLAY": {
    "de": "Betätigen des Symbols aktiviert das Vorlesen des Textes.",
    "en": "Press the speaker to play a spoken description"
  },
  "AUDIO_STOP": {
    "de": "Betätigen des Symbols beendet das Vorlesen des Textes",
    "en": "When text is being spoken, you can press the stop button to stop the text"
  },
  "SIEMENS_INFORMATION": {
    "de": "Detailierte informationen über die Komponente",
    "en": "Get detailed information about selected component"
  },
  "SIEMENS_CLOSE": {
    "de": "Dialog schließen",
    "en": "Close dialog"
  },
  "TRANSPARENCY_MODE": {
    "de": "Transparenzmodus",
    "en": "Transparency mode"
  },
  "TRANSPARENCY_MODE_DESCRIPTION": {
    "de": "Ermöglicht Ihnen die Szene transparent / X-Ray-Modus zu betrachten",
    "en": "Provides a rough look under the components and is used to display the animations"
  },
  "ROTATE_CAMERA": {
    "de": "Rotiert die Kamera und ermöglicht das Selektieren von Komponenten",
    "en": "Rotate camera & select components"
  },
  "MOVE_CAMERA": {
    "de": "Kamera bewegen",
    "en": "Move camera"
  },
  "ZOOM": {
    "de": "Ein- und rauszoomen",
    "en": "Zoom in and out"
  },
  "HEADER_TUTORIAL": {
    "de": "Wähle ein Symbol für die Funktionen:",
    "en": "Select an icon for the respective functions:"
  },
  "RESET_VIEW": {
    "de": "Ansicht zurücksetzten",
    "en": "Reset View"
  },
  "ANIMATION_TUTORIAL": {
    "de": "Ändern der Geschwindigkeit der Animation",
    "en": "Move to change speed of training animation"
  },
  "SLOW_ANIMATION": {
    "de": "Links = langsamer",
    "en": "Left = Slow animation"
  },
  "FAST_ANIMATION": {
    "de": "Rechts = schneller",
    "en": "Right = Fast animation"
  },
  "LEARNING_SECTION": {
    "de": "Lernbereich zum Kennenlernen der Komponentenbegriffe sowie deren Anordnung und Lage im Weichenantrieb.",
    "en": "Learning Section to get to know components and their placement and functionality in the switch point drive."
  },
  "COMPONENT_LEVEL": {
    "de": "1. Ebene: Hier können Sie eine Komponentengruppe auswählen.\n 2. Ebene: Hier können Sie eine Teilkomponente aus einer Komponentengruppe auswählen.",
    "en": "Level 1: Select assembly in the model\n Level 2: Select a single sub component of an assembly."
  }
}
