import { Translations } from "./translations"

export const translations_trainings: Translations = {
  "TRAINING.3_6_THROW_BAR_EXTENDING.STEP1": {
    "de": "Das Umstellen der Weiche ist eine Hauptfunktion des Weichenantriebs. Es erfolgt durch das Bewegen der Weichenzungen. Die Bewegungen werden, vom Weichenantrieb aus betrachtet, in Ausfahren und Einfahren des Stellschiebers unterschieden.",
    "en": "Switching the turnout is one of the main functions of the turnout mechanism. It is performed by moving the switch blades. From the point of view of the point machine, the movements are divided into extending and retracting the throw bar."
  },
  "TRAINING.3_6_THROW_BAR_EXTENDING.STEP1.AUDIO": {
    "de": "VR_ELS710_30_DE_0.mp3",
    "en": "VR_ELS710_30_EN_0.mp3"
  },
  "TRAINING.3_6_THROW_BAR_EXTENDING.ORDERED_TASK.TITLE": {
    "de": "1. Ausfahren des Stellschiebers",
    "en": "1. Extending throw bar"
  },
  "TRAINING.3_6_THROW_BAR_EXTENDING.STEP2.ORDERED_TASK_INSTRUCTION": {
    "de": "Stellen Sie den Energiefluss dar, der beim Umstellvorgang “Ausfahren” erzeugt wird.\nKlicken Sie dazu die 10 Komponenten im Weichenantrieb in der entsprechenden Reihenfolge an.\n\nBei richtiger Reihenfolge startet die Animation automatisch.",
    "en": "Display the energy flow generated during the process \"Extending\".\nClick on the 10 components in the point machine in the corresponding order.\n\nIf the sequence is correct, the animation will start automatically."
  },
  "TRAINING.3_6_THROW_BAR_EXTENDING.STEP2.ORDERED_TASK_INSTRUCTION.AUDIO": {
    "de": "VR_ELS710_30_DE_1.mp3",
    "en": "VR_ELS710_30_EN_1.mp3"
  },
  "TRAINING.3_7_THROW_BAR_RETRACTING.MENU_ITEM": {
    "de": "Einheit 2: Einfahren des Stellschiebers",
    "en": "Unit 2: Retracting throw bar"

  },
  "TRAINING.3_7_EINFAHREN.ORDERED_TASK.TITLE": {
    "de": "2. Einfahren des Stellschiebers",
    "en": "2. Retracting throw bar"
  },
  "TRAINING.3_7_EINFAHREN.ORDERED_TASK.TEXT": {
    "de": "Das Umstellen der Weiche ist eine Hauptfunktion des Weichenantriebs. Es erfolgt durch das Bewegen der Weichenzungen. Die Bewegungen werden, vom Weichenantrieb aus betrachtet, in Ausfahren und Einfahren des Stellschiebers unterschieden.",
    "en": "Switching the turnout is one of the main functions of the turnout mechanism. It is performed by moving the switch blades. From the point of view of the point machine, the movements are divided into extending and retracting the throw bar."
  },
  "TRAINING.3_7_EINFAHREN.ORDERED_TASK.AUDIO": {
    "de": "VR_ELS710_31_DE_0.mp3",
    "en": "VR_ELS710_31_EN_0.mp3"
  },
  "TRAINING.3_7_EINFAHREN.STEP2.ORDERED_TASK_INSTRUCTION": {
    "de": "Sie den Energiefluss dar, der beim Umstellvorgang \"Einfahren” erzeugt wird.\n\n    Klicken Sie dazu die 10 Komponenten im Weichenantrieb in der entsprechenden Reihenfolge an.\n\nBei richtiger Reihenfolge startet die Animation automatisch.",
    "en": "Display the energy flow generated during the process \"Retracting\".\n\n    Click on the **10 components** in the point machine in the **corresponding order**.\n\nIf the sequence is correct, the animation will start automatically."
  },
  "TRAINING.3_7_EINFAHREN.STEP2.ORDERED_TASK_INSTRUCTION.AUDIO": {
    "de": "VR_ELS710_31_DE_1.mp3",
    "en": "VR_ELS710_31_EN_1.mp3"
  },
  "TRAINING.3_8_VERSCHLIEßEN.ORDERED_TASK.TITLE": {
    "de": "3. Verschließen / Sichern der Endlage",
    "en": "3. Closing / Securing the end state"
  },
  "TRAINING.3_8_VERSCHLIEßEN.ORDERED_TASK.TEXT": {
    "de": "Das Sichern der Weichenzungen in ihrer Endlage nach dem Umstellvorgang ist eine Hauptfunktion des Weichenantriebs.\n\n \n\nUm Entgleisungen zu vermeiden, müssen die Weichenzungen in der Endlage festgehalten werden. Dazu gehören sowohl das Anliegen der anliegenden Zunge an der Backenschiene sowie die Gewährleistung der Durchfahrrille der abliegenden Zunge. Die Festhaltung muss besonders robust ausgeführt werden, da eine Weiche bei Überfahrt eines Eisenbahnfahrzeuges erheblichen mechanischen Belastungen ausgesetzt ist. Die Festhaltung erfolgt außerhalb des Weichenantriebs ELS710 durch einen Außenverschluss.",
    "en": "Securing the switch blades in their end position after the changeover process is a main function of the point machine.\n\nTo prevent derailments, the switch blades must be held in their end position. This includes both the contact of the adjacent switch blade with the stock rail as well as\nensuring that the tongue passes through the groove.\n\nThe locking mechanism must be particularly robust since a switch is subjected to considerable mechanical loads when a rail vehicle passes over it.\n\nThe locking is affected outside the ELS710 point machine by means of an external lock."
  },
  "TRAINING.3_8_VERSCHLIEßEN.ORDERED_TASK.AUDIO": {
    "de": "VR_ELS710_32_DE_0.mp3",
    "en": "VR_ELS710_32_EN_0.mp3"
  },

  "TRAINING.3_8_VERSCHLIEßEN.STEP2.ORDERED_TASK_INSTRUCTION": {
    "de": "Sichern Sie die Endlage des Weichenantriebs. Wählen Sie dazu die 4 Komponenten des Weichenantriebs aus, die hierbei zum Einsatz kommen.\n\nBei richtiger Reihenfolge startet die Animation automatisch.",
    "en": "Secure the end position of the point machine. To do this, select the 4 components of the point machine that are to be used in the correct order.\n\nIf the sequence is correct, the animation will start automatically."
  },
  "TRAINING.3_8_VERSCHLIEßEN.STEP2.ORDERED_TASK_INSTRUCTION.AUDIO": {
    "de": "VR_ELS710_32_DE_1.mp3",
    "en": "VR_ELS710_32_EN_1.mp3"
  },
  "TRAINING.3_9_UBERWACHEN.ORDERED_TASK.TITLE": {
    "de": "4. Überwachen der Endlagen",
    "en": "4. Supervising end state"
  },
  "TRAINING.3_9_UBERWACHEN.ORDERED_TASK.TEXT": {
    "de": "Das Überwachen der Weichenzungen in ihrer Endlage nach dem Verschließen/Sichern der Endlage ist eine Hauptfunktion des Weichenantriebes.\n\nNachdem die Weichenzungen in derEndlage festgehalten werden, muss nun die Endlage geprüft werden.\n\nVor Zulassung der Fahrt über eine Weiche muss geprüft werden, ob die Weiche sich in der richtigen Endlage befindet. Anschließend ist zu prüfen, ob die Weiche in der Endlage verbleibt.\n\nDadurch wird die Funktion der Umstell- und Festhalteeinrichtungen überwacht.",
    "en": "Monitoring the switch blades in their end position after locking/securing the end position is a main function of the point machine.\n\nAfter the switch blades have been locked in their end position, the end position must now be checked.\n\nBefore permitting travel over a turnout, it must be checked whether the turnout is in the correct end position.\nThen it must be checked whether the turnout remains in the end position.\nThis monitors the function of the changeover and holding devices."
  },
  "TRAINING.3_9_UBERWACHEN.ORDERED_TASK.AUDIO": {
    "de": "VR_ELS710_33_DE_0.mp3",
    "en": "VR_ELS710_33_EN_0.mp3"
  },
  "TRAINING.3_9_UBERWACHEN.STEP2.ORDERED_TASK.TITLE": {
    "de": "4. Überwachen der Endlagen",
    "en": "4. Supervising end state"
  },
  "TRAINING.3_9_UBERWACHEN.STEP2.ORDERED_TASK_INSTRUCTION": {
    "de": "Überwachen Sie die Endlage des Weichenantriebs. Wählen Sie dazu die vier Komponenten aus, die hierbei zum Einsatz kommen.\n\nBei richtiger Reihenfolge startet die Animation automatisch.",
    "en": "Monitor the end position of the point machine. To do this, select the 4 components that are used here in the correct order.\n\n\nIf the sequence is correct, the animation will start automatically."
  },
  "TRAINING.3_9_UBERWACHEN.STEP2.ORDERED_TASK_INSTRUCTION.AUDIO": {
    "de": "VR_ELS710_33_DE_1.mp3",
    "en": "VR_ELS710_33_EN_1.mp3"
  }
}
