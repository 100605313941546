import { CdkDrag } from "@angular/cdk/drag-drop"
import { Component, Input, ViewChild } from "@angular/core"
import { TranslateService } from "@ngx-translate/core"
import { Subject } from "rxjs"
import { map } from "rxjs/operators"
import { DialogButton, IHidableObject, InternalXRDialog, XRDialogEvent } from "src/app/components/dialog"
import { SpeakService, SpeakState } from "src/app/services/speak.service"

@Component({
  selector: "app-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.scss"]
})
export class DialogComponent {
  @ViewChild("drag") private dragElem!: CdkDrag
  @Input()
  public get dialogContent(): InternalXRDialog | undefined { return this._dialogContent }
  public set dialogContent(dialogContent: InternalXRDialog | undefined) {
    if (this._dialogContent?.align !== dialogContent?.align && this.dragElem) {
      this.dragElem.reset()
    }
    this._dialogContent = dialogContent
    this.showHiddenButton = dialogContent?.hideButton != undefined
    this.hidable = dialogContent?.hideButton
  }
  @Input() public xrDialogEventSubject?: Subject<XRDialogEvent>
  public _dialogContent?: InternalXRDialog
  public hidable?: IHidableObject = undefined
  public showHiddenButton = false
  public isExpanded = true
  public closingRequest = false
  public values$ = this.speak.speakingState.pipe(map(state =>
  {
    switch (state) {
      case SpeakState.Speaking:
        return {
          isPlaying: true
        }

      case SpeakState.NotSpeaking:
        return {
          isPlaying: false
        }
      default:
        return {
          isPlaying: false
        }
    }
}
  ))

  public unselect() {}

  public callEvent(button: DialogButton | undefined) {
    if (!button) { return }
    this.xrDialogEventSubject?.next({ type: "BUTTON_PRESS", payload: button.payload })
    if(button?.isClosing === true) {
     this.close("FORCE_CLOSE")
    }
  }

  public mdEvent(el: any) {
    console.log(el)
  }

  public toggleCollapse() {
    this.isExpanded = !this.isExpanded
  }

  /**
   * Manages closing behavior
   *
   * In general closing event is being submitted
   *
   * If the dialog is closable, the dialog gets closed, if the closing behavior is just "EVENT", the dialog is not closed
   * @param event when "FORCE_CLOSE" is provided the dialog gets closed
   */
  public close(event?: "FORCE_CLOSE") {
    this.stop()
    this.xrDialogEventSubject?.next({ type: "CLOSE", payload: this._dialogContent?.onClosePayload})
    if (event === "FORCE_CLOSE" || this.dialogContent?.closingBehavior === "CLOSABLE") {
      this.dialogContent?.close?.call(this)
    }
  }

  public cancelClosingRequest() {
    this.closingRequest = false
  }

  public requestClosing() {
    if (this.dialogContent?.dialogCloseConfirmMessage) {
      this.closingRequest = true
    } else {
      this.close()
    }

  }

  public async play() {
    if (this.dialogContent?.playAudioPath) {
      if (this.dialogContent.playAudioPath.endsWith(".mp3")) {
        this.speak.playAudioFile(this.dialogContent.playAudioPath)
      } else {
        const path = "../../../assets/audio/" + await this.translation.get(this.dialogContent.playAudioPath).toPromise()
        this.speak.playAudioFile(path)
      }

    } else {
      this.speak.play(this.dialogContent?.mainContent[0].content ?? "")
    }
  }

  public stop() {
    if (this.dialogContent?.playAudioPath) {
      this.speak.stopAudioFile()
    } else {
      this.speak.stop()
    }
  }

  public constructor(
    private speak: SpeakService,
    public translation: TranslateService
  ) {}
}
