import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, NgZone, QueryList, ViewChildren, inject, signal } from "@angular/core"
import { AsyncPipe, NgComponentOutlet, NgFor, NgIf, NgStyle } from "@angular/common"
import { CdkDrag, CdkDragMove } from "@angular/cdk/drag-drop"
import { WindowsService } from "./windows.service"

@Component({
  standalone: true,
  selector: 'app-windows',
  templateUrl: './windows.component.html',
  styleUrls: ['./windows.component.scss'],
  imports: [NgFor, NgIf, AsyncPipe, NgComponentOutlet, CdkDrag, NgStyle],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WindowsComponent implements AfterViewInit {
  @ViewChildren("window") protected windowRefs!: QueryList<ElementRef>

  private markForCheck = inject(ChangeDetectorRef).markForCheck
  private ngZone = inject(NgZone)
  private windowService = inject(WindowsService)

  protected windows = this.windowService.windows

  protected dragging = signal(false)


  private distanceX: number  = 0
  private distanceY: number  = 0

  public ngAfterViewInit(): void {
    this.markForCheck()
  }

  public requestClosing(i: number) {
    this.windowService.closeWindow(i)
  }

  public windowId(index: number, window: any) {
    return window.id
  }

  public toFront(i: number) {

    this.windowRefs.forEach((window, index) => {
      if (i === index) {
        (window.nativeElement as HTMLElement).style.zIndex = "" + 100 + this.windows().length
      } else {
        (window.nativeElement as HTMLElement).style.zIndex = "" + 100 + index
      }

    })

  }

  protected dragMove(dragHandle: HTMLElement, windowIndex: number, $event: CdkDragMove<any>, windowDragRef: CdkDrag) {
    const htmlElement = this.windowRefs.get(windowIndex)?.nativeElement
    this.ngZone.runOutsideAngular(() => {
     this.resize(dragHandle, htmlElement, $event, windowDragRef)
    })
  }

  protected dragStarted() {
    this.distanceX = 0
    this.distanceY = 0
    this.dragging.set(true)
  }

  protected dragEnded() {
    this.distanceX = 0
    this.distanceY = 0
    this.dragging.set(false)
  }

  private resize(dragHandle: HTMLElement, target: HTMLElement,$event: CdkDragMove<any>, windowDragRef: CdkDrag) {
    const targetRect = target.getBoundingClientRect()

    // Calculate the distance since the last resize call. this.distance* is being reset on end drag
    const distX = $event.distance.x - this.distanceX
    const distY = $event.distance.y - this.distanceY

    // Remember distance so the resizing does not accumulate
    this.distanceY = $event.distance.y
    this.distanceX = $event.distance.x

    if(dragHandle.classList.contains("left")) {
      const width = targetRect.width - distX
      const dragPosition = windowDragRef.getFreeDragPosition()
      windowDragRef.setFreeDragPosition({ x: dragPosition.x + distX, y: dragPosition.y})
      target.style.width = width + "px"
    }
    if (dragHandle.classList.contains("right")) {
      const width = targetRect.width + distX
      target.style.width = width + "px"
    }
    if(dragHandle.classList.contains("bottom")) {
      const height = targetRect.height + distY
      target.style.height = height + "px"
    }

    if(dragHandle.classList.contains("top")) {
      const height = targetRect.height - distY
      target.style.height = height + "px"
      const dragPosition = windowDragRef.getFreeDragPosition()
      windowDragRef.setFreeDragPosition({ x: dragPosition.x, y: dragPosition.y + distY})
    }











/*
    const matched = target.style.transform.match(/translate3d\((\d*)px,?\s*(\d*)px,\s*(\d*)px\)/)
    if (matched != null) {
      const tX = parseInt(matched[1]) + distX
      const tY = parseInt(matched[2])
      const tZ = parseInt(matched[3])

      target.style.transform = `translate3d(${tX}px, ${tY}px, ${tZ}px)`
    }
*/
    dragHandle.style.transform = "translate(0, 0)"
  }
}
