import { Injectable } from "@angular/core"
import { Languages } from "../models/languages"
import { translations } from "../utils/translations"

@Injectable({
  providedIn: "root"
})
export class TranslationService {
  private translations = translations
  #language: Languages = "de"



  public set language(language : Languages) {
    this.#language = language
  }


  public get language() : Languages {
    return this.#language
  }



  public translate(key:string) {
    return this.translations[key]?.[this.#language] ?? key
  }
}
