<div class="dialog" [ngClass]="{
  'dialog-left': dialogContent?.align === 'left',
  'dialog-right': dialogContent?.align === 'right'
  }" cdkDrag cdkDragBoundary=".wrapper" #drag="cdkDrag">
  <div class="header" cdkDragHandle>
    <h2 [joyrideStep]="'step4'" [text]="'TRAINING.COMPONENT' | translate">{{ dialogContent?.title ?? "" | translate }}</h2>
    <span>
    <div class="item-buttons" [joyrideStep]="'step5'" [stepContent]="itemButtonTutorial">
      <button *ngIf="showHiddenButton && hidable?.isHidden" type="button" class="btn btn-link medium-icon-btn" (click)="hidable?.toggle()"><i
          class="siemens-hide"></i></button>
      <button *ngIf="showHiddenButton && !hidable?.isHidden" type="button" class="btn btn-link medium-icon-btn" (click)="hidable?.toggle()"><i
          class="siemens-show"></i></button>
      <button *ngIf="false && dialogContent?.isCollapsable" type="button" class="btn btn-link medium-icon-btn" (click)="toggleCollapse()"> <i class="siemens-information"></i> </button>
      <button *ngIf="dialogContent?.closingBehavior !== 'NOT_CLOSABLE'" type="button" class="btn btn-link medium-icon-btn" (click)="requestClosing()"> <i
          class="siemens-close-small"></i> </button>
      </div>
  </span>
    <ng-template #itemButtonTutorial>
      <span><i class="siemens-hide"         style="font-size: 16px"></i> {{ "SIEMENS_HIDE" | translate }}</span><br>
      <span><i class="siemens-close-small"  style="font-size: 16px"></i> {{ "SIEMENS_CLOSE" | translate }}</span><br>
    </ng-template>
    <ng-template #itemaudioTutorial>
      <span><i class="siemens-volume-loud"  style="font-size: 16px"></i> {{ "AUDIO_PLAY" | translate }}</span><br>
      <span><i class="siemens-stop"  style="font-size: 16px"></i> {{ "AUDIO_STOP" | translate }}</span><br>
    </ng-template>
  </div>
  <div *ngIf="dialogContent?.mainContent?.[0]?.content && closingRequest === false" class="content mx-5 mb-5">
    <ng-container *ngIf="values$ | async as values">
      <span [joyrideStep]="'step51'" [stepContent]="itemaudioTutorial">
      <button *ngIf="dialogContent?.playAudioPath !== undefined && values.isPlaying === false" type="button" class="btn btn-link medium-icon-btn playButton" (click)="play()"> <i
        class="siemens-volume-loud"></i> </button>
      <button *ngIf="dialogContent?.playAudioPath !== undefined && values.isPlaying === true" type="button" class="btn btn-link medium-icon-btn playButton" (click)="stop()"> <i
          class="siemens-stop"></i> </button>
        </span>
    </ng-container>

    <ngx-si-markdown (mdEvent)="mdEvent($event)" class="text" [text]="dialogContent?.mainContent?.[0]?.content ?? '' | translate"></ngx-si-markdown>
  </div>
  <div *ngIf="closingRequest === true" class="content mx-5 mb-5">
    <p>{{dialogContent?.dialogCloseConfirmMessage ?? "" | translate}}</p>

  </div>
  <div *ngIf="dialogContent?.footer && closingRequest === false" class="footer m-3">
    <button type="button" style="color:#fe8389;" class="btn btn-link medium-icon-btn" (click)="unselect()"> <i
      class="siemens-close-small"></i>Test </button>
  </div>
  <div *ngIf="closingRequest === true" class="footer m-3">
    <div class="row justify-content-between">
      <div class="col-md-auto">
        <button type="button" class="btn btn-primary small-icon-btn" (click)="cancelClosingRequest()">{{"GENERAL.NO" | translate}}</button>
      </div>
      <div class="col-md-auto">
        <button type="button" class="btn btn-danger small-icon-btn" (click)="close()">{{"GENERAL.YES" | translate}}</button>
      </div>
    </div>
  </div>
  <div *ngIf="dialogContent?.buttons && closingRequest === false" class="footer m-3" [joyrideStep]="'step52'" [stepContent]="itemAdditionalButtonsTutorial">
    <div class="row justify-content-between">
      <div *ngFor="let button of dialogContent?.buttons" class="col-md-auto">
        <button type="button" class="btn btn-link" (click)="callEvent(button)"> {{button.text  | translate}} </button>
      </div>
  </div>
  </div>
  <ng-template #itemAdditionalButtonsTutorial>
    <p>{{ "TRAININGS.DIALOG_ADDITIONAL_BUTTONS" | translate }}</p>
    <ul>
      <li>{{ "TRAININGS.DIALOG_ADDITIONAL_BUTTON1" | translate }}</li>
      <li>{{ "TRAININGS.DIALOG_ADDITIONAL_BUTTON2" | translate }}</li>
    </ul>
  </ng-template>
</div>
