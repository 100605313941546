<amplify-authenticator [socialProviders]="['amazon']" [hideSignUp]="true">
  <ng-template amplifySlot="sign-in-footer">
    <div></div>
  </ng-template>
  <ng-template amplifySlot="authenticated" let-user="user" let-signOut="signOut">
    <router-outlet></router-outlet>
  </ng-template>
</amplify-authenticator>
<!-- <main [@siRouterFadeAnimation]="getRouteAnimation(route)" class="flex-fill">
  <router-outlet #route="outlet"></router-outlet>
</main> -->
<!--div class="d-flex flex-column min-vh-100">
  <app-header></app-header>


  <footer class="si-footer si-footer-legal-only">
    <div class="container">
      <span class="si-application-name">Inspector</span>
      <si-copyright></si-copyright>
      <si-legal-links></si-legal-links>
    </div>
  </footer>
  <div-->
