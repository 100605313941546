import { functionalities } from "./interactions_functionalities"
import { trainings } from "./interactions_trainings"
import { Interaction } from "./utils"

export const interactions: Record<string,Interaction> = {
  ...functionalities,
  ...trainings,
  "Components Intro": {
    type: "SIMPLE",
    actions: [{
      type: "SHOW_DIALOG",
      text: "COMPONENTS.INTRO.TEXT",
      title: "COMPONENTS.INTRO.TITLE",
    }]
  },
  "Training Intro": {
    type: "SIMPLE",
    actions: [{
      type: "SHOW_DIALOG",
      text: "TRAINING.INTRO.TEXT",
      title: "TRAINING.INTRO.TITLE",
    }]
  },
  "Functionality Intro": {
    type: "SIMPLE",
    actions: [{
      type: "SHOW_DIALOG",
      text: "ANIMATION.INTRO.TEXT",
      title: "ANIMATION.INTRO.TITLE",
    }]
  },
  "Not_Yet_Implemented": {
    type: "SIMPLE",
    actions: [{
      type: "SHOW_DIALOG",
      text: "TRAINING_NOT_YET_IMPLEMENTED.TEXT",
      title: "TRAINING_NOT_YET_IMPLEMENTED.TITLE",

    }]
  }







 }
