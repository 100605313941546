<div class="container">
  <section>
    <div class="row">
      <div class="col">
        <h1>Simpl Siemens Brand Starter demo</h1>
        <p>Here are some resources to get started</p>
      </div>
    </div>
  </section>

  <section>
    <h2>SiMPL & Angular</h2>
    <div class="row">
      <div class="col">
        <a href="https://simpl.code.siemens.io/simpl/">
          <button class="btn btn-outline-primary">
            <i class="siemens-home h2"></i><br>
            SiMPL Home
          </button>
        </a>
        <a href="https://angular.io/tutorial">
          <button class="btn btn-outline-primary">
            <i class="siemens-link-extern h2"></i><br>
            Learn Angular
          </button>
        </a>
        <a href="https://simpl.code.siemens.io/simpl/getting-started/build-web-application/">
          <button class="btn btn-outline-primary">
            <i class="siemens-information h2"></i><br>
            SiMPL Web App Guide
          </button>
        </a>
        <a href="https://simpl.code.siemens.io/simpl/fundamentals/architecture/">
          <button class="btn btn-outline-primary">
            <i class="siemens-browse-all h2"></i><br>
            SiMPL Techstack
          </button>
        </a>
        <a href="https://simpl.code.siemens.io/simpl/community/workshops/">
          <button class="btn btn-outline-primary">
            <i class="siemens-show h2"></i><br>
            SiMPL Workshops
          </button>
        </a>
      </div>
    </div>
  </section>

  <section>
    <h2>Siemens Brand</h2>
    <div class="row">
      <div class="col">
        <a href="https://brandville.siemens.com/en/design-elements">
          <button class="btn btn-outline-primary">
            <i class="siemens-home h2"></i><br>
            Brandville Design Elements
          </button>
        </a>
        <a href="https://simpl.code.siemens.io/simpl-siemens-brand/components/">
          <button class="btn btn-outline-primary">
            <i class="siemens-browse-all h2"></i><br>
            SiMPL Components
          </button>
        </a>
        <a href="https://simpl.code.siemens.io/simpl-icon-demo/#/siemens/">
          <button class="btn btn-outline-primary">
            <i class="siemens-image h2"></i><br>
            SiMPL Icons
          </button>
        </a>
      </div>
    </div>

    <h3>Demos</h3>
    <div class="row">
      <div class="col">
        <a href="https://simpl.code.siemens.io/simpl-siemens-brand/demo-dark/#/">
          <button class="btn btn-outline-primary">
            <i class="siemens-play h2"></i><br>
            SiMPL Demo App (Dark)
          </button>
        </a>
        <a href="https://simpl.code.siemens.io/simpl-siemens-brand/demo-light/#/">
          <button class="btn btn-outline-primary">
            <i class="siemens-play h2"></i><br>
            SiMPL Demo App (Light)
          </button>
        </a>
      </div>
    </div>
  </section>
</div>
