import { AfterViewInit, Component, ElementRef, OnInit, TemplateRef, ViewChild, ViewContainerRef } from "@angular/core"
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms"

import { BsModalRef, BsModalService, ModalOptions } from "ngx-bootstrap/modal"
import { Indexed, Model, Ooi } from "../../models/model"
import { ScrewInfoService } from "src/app/services/screw-info.service"


//import app specific json <- this should contain all project specific information
import appinfoJson from "../../../assets/switchapp.json"
import { BabylonjsInteractionService } from "src/app/services/babylonjs-interaction.service"
import { BabylonEngineService, PickMode } from "../../services/babylon-engine.service"
import { XRManagerService } from "src/app/services/xrmanager.service"
import { DialogService } from "src/app/components/dialog"
import { KeyboardEventTypes, WebXRState } from "@babylonjs/core"
import { GuidedInteractionServiceService } from "src/app/services/guided-interaction-service.service"
import {JoyrideService} from "ngx-joyride"
import {BsDropdownDirective} from "ngx-bootstrap/dropdown"
import { MenuButton } from "src/app/utils/utils"
import glossaryJson from "../../../assets/glossary.json"
import { TranslateService } from "@ngx-translate/core"
import { BehaviorSubject, Subject } from "rxjs"
import { buffer, debounceTime, filter, map, shareReplay, switchMap } from "rxjs/operators"
import { ModelManagementService } from "src/app/services/model-management.service"
//import appinfoJson from '../../../assets/bogieapp.json';
interface Glossar {
  abbreviation: string;
  meaning: string;
}

@Component({
  selector: "app-inspector-main",
  templateUrl: "./inspector-main.component.html",
  styleUrls: ["./inspector-main.component.scss"]
})

export class InspectorMainComponent implements OnInit, AfterViewInit {
  @ViewChild("canvasRef", { static: true }) public canvasRef!: ElementRef<HTMLCanvasElement>
  @ViewChild("canvasRef", { read: ViewContainerRef }) private canvasViewRef!: ViewContainerRef
  @ViewChild("itemDropdown", { static: true }) public itemDropdown: any
  @ViewChild("itemDropdownGroup", { static: true }) public itemDropdownGroup: any
  @ViewChild("dropdown", {static : false}) public dropdown!: BsDropdownDirective

  public version = "2212151449"


  public myAnimationSpeed: number = 2
  public speedControl = new UntypedFormControl()
  public menuOois: Indexed<Ooi>[] = [] // read from CSV
  public animationOptions: any[] = [] //read from json
  public componentsOptions: {id: number, content: MenuButton }[] = []
  public trainingsOptions: {id: number, content: MenuButton }[] = [] //read from json
  public glossory: Glossar[] = glossaryJson //read from json

  public form!: UntypedFormGroup
  public showExplored: boolean = false
  public hasVariants: boolean = false //hack


  public get isFreeMode() : boolean {
    return !this.guidedInteractionServiceService.isInTraining
  }

  public dropdownAutoClose : boolean = false
  public doBlur : boolean = true

  //prod
  //public myModel?: Model
  public modelSub = new BehaviorSubject<Model | undefined>(undefined)


  public menuOois$ = this.modelService.modelSub.asObservable().pipe(
    filter((model): model is Model => model != null),
    switchMap(model => {
      return model.oois$.pipe(
      map(oois => {
        return oois.filter(ooi => ooi.parent == null)
      .map((ooi, index) => ({ id: index, content: ooi }))})

    )}),
    shareReplay(1)
  )


  //
  public languages: string[] = []

  public isArMode$ = this.xrManager.isArMode$
  public modalRef!: BsModalRef
  public picking = true
  public ambientOcc = false
  public speedRatio = 2
  private variants: Record<string, string>
  public selectedItem?: Ooi
  public screwMode: boolean = false
  public screws: any[] = []
  public animationSpeed: any
  public highlightAnimated: boolean = true
  public isShowDebug = false

  private currentVariant: any
  #isPause = false

  #headerClickSubject = new Subject()

  public get isPause() : boolean {
    return this.#isPause
  }


  public set isPause(pause : boolean) {
    if (pause === true) {
      this.bsEngineService.speedRatio = 0

    } else {
      this.bsEngineService.speedRatio = this.speedRatio
    }

    this.#isPause = pause
  }

  public get variantOptions() {
    return Object.keys(this.variants).map((variantName, id) => (
      {
        id,
        content: this.variants[variantName]
      }
    ))
  }

  public trackById(_: any , item: Ooi) {
    return item.id
  }

  public trackByMenu(_: any , item: any) {
    return item.id
  }

  public constructor(
    public formBuilder: UntypedFormBuilder,
    public modalService: BsModalService,
    private screwInfoService: ScrewInfoService,
    private bsEngineService: BabylonEngineService,
    private babylonInteractionService: BabylonjsInteractionService,
    private readonly joyrideService: JoyrideService,
    private xrManager: XRManagerService,
    private dialogService: DialogService,
    private guidedInteractionServiceService: GuidedInteractionServiceService,
    public translation: TranslateService,
    public modelService: ModelManagementService) {

      this.#headerClickSubject
      .pipe(
        buffer(this.#headerClickSubject.pipe(debounceTime(250))),
        map(clicks => clicks.length),
        filter(clicksLength => clicksLength >= 3)
      ).subscribe(_ => this.setDebugMode())


      translation.onLangChange.subscribe((params) => {
        if(this.selectedItem) {
          this.select(this.selectedItem)
        }
      })

      const components = (appinfoJson).components as MenuButton[]
      this.componentsOptions = components.map((animation, id) => (
        {
          id,
          content: animation
        })
      )

      const animations = (appinfoJson).animations as MenuButton[]
      this.animationOptions = animations.map((animation, id) => (
        {
          id,
          content: animation
        })
      )

      const trainings = (appinfoJson).trainings as MenuButton[]
      this.trainingsOptions = trainings.map((training, id) => (
        {
          id,
          content: training
        }))

      screwInfoService.inspector = this
      this.variants = appinfoJson.variants

  }
  public ngAfterViewInit(): void {
    this.dialogService.parentRef = this.canvasViewRef
    this.showTutorial()
  }

  public startTour() {
    this.doBlur = true
    this.joyrideService.startTour(
        {
          steps: ["step1", "step2", "step3", "step4", "step5","step51", "step52", "step6", "step7", "step8", "step9"],
          customTexts: {
            next: this.translation.instant("GENERAL.NEXT"),
            prev: this.translation.instant("GENERAL.PREV"),
            done: this.translation.instant("GENERAL.DONE"),
          } } //  steps order
    ).subscribe(
        (step) => {
          if (step.number in [1,2,3,4]) {
            this.dropdownAutoClose = false
            this.dropdown.show()
          } else {
            this.dropdownAutoClose = true
            this.dropdown.hide()
          }
          if ([3,4,5,6, 7, 8].includes(step.number)) {
            //this.onItemSelected(this.menuOois[2].content.children[0])
            this.dialogService.show({
              hideButton: { isHidden: true, toggle: () => {}},
              mainContent: [{
                type: "text",
                content: this.translation.instant("TRAININGS.DIALOG_SAMPLE_TEXT")
              }],
              title: "Title",
              playAudioPath: "",
              isCollapsable: true,
              align: "right",
              buttons: [{ text: "GENERAL.RESET_VIEW" }, { text: "GENERAL.PLAY_ANIMATION" }]

            })
          }
          if (step.number === 9) {
            this.unselect()
            this.doBlur = false
            this.modelService.toggleTransparent(true)
          } else if (step.number === 10) {
            this.doBlur = true
            this.modelService.toggleTransparent(false)
          }
        },
        err => {},
        () => {
          this.dropdownAutoClose = true
          this.unselect()
          this.showDialog()
        }
    )
  }

  public openModal(template: TemplateRef<any>) {
    let options: ModalOptions = {}
    options.class = "big-modal"
    //this.modalRef = this.modalService.show(template,options);
    this.modalRef = this.modalService.show(template)
    if (this.selectedItem) {
      this.selectedItem.explored = true
    }
  }

  public startTraining() {
    return this.guidedInteractionServiceService.startInteraction("Ausfahren")
  }

  public setAnimationSpeed(value: string) {
    let number = parseFloat(value)
    this.bsEngineService.speedRatio = number
  }

  public togglePause() {
    this.isPause = !this.isPause
  }

  public async ngOnInit() {
    this.languages = this.translation.getLangs()
    await this.bsEngineService.createScene(this.canvasRef.nativeElement)
    await this.createScene()
    this.form = this.formBuilder.group({
      ObjectMultiWithT: [],
      objectLanguage: [this.translation.currentLang]
    })
  }

  public toggleTransparent() {
    this.bsEngineService.updateWithFreeze(() => {
      this.modelService.toggleTransparent()
    })
  }

  public reset() {
    this.modelService.resetModel()
  }

  public objectValueProvider(object: any) {
    return object.content
  }

  public objectKeyProvider(object: any) {
    return object.id
  }

  private async createScene() {
    this.bsEngineService.hit$.subscribe(el => {
      if(el.type === PickMode.SinglePick && el.picked.length === 1 && this.isFreeMode === true) {
        const mesh = el.picked[0]
        if (mesh && mesh.isEnabled()) {
          //console.log(mesh.name)
          let ooi = this.modelService.findOOI(mesh)
          if (ooi) {
            this.select(ooi)
          }
        }
      }

      else if(el.type === PickMode.MultiPick && el.picked.length >= 1) {
        el.picked.forEach(el => this.screwInfoService.handleHit(el))
      }

    })

    await this.modelService.loadModel(appinfoJson.model, appinfoJson.variants)
    await this.modelService.activateVariation("ELS710")
    this.menuOois = this.modelService.menuOois
    this.modelService.modelSub.subscribe(model => {
      this.modelSub.next(model)
      model?.ooisSubject.next()
    })

    this.bsEngineService.xrExperience?.baseExperience.onStateChangedObservable.add(x => {
      if (x == WebXRState.IN_XR) {
        this.enterAR()
      }
    })

    this.bsEngineService.scene.onKeyboardObservable.add(async kbInfo => {
      switch (kbInfo.type) {
        case KeyboardEventTypes.KEYDOWN:
          if (kbInfo.event.key.toLowerCase() == "d") {
            this.bsDebugMode()
          }
          break
      }
    })
  }

  public async bsDebugMode() {
    const debugModule = await import(/* webpackChunkName: "debug" */ "../../debug/appDebug")
    debugModule.toggleDebugMode(this.bsEngineService.scene)
  }


  public onAnimationSelected(selectedAnim: { id: number, content: MenuButton}) {
    if (this.isFreeMode === false) { return }
    this.guidedInteractionServiceService.startInteraction(selectedAnim.content.value).subscribe()
  }

  public onTrainingSelected(selectedTraining: { id: number, content: MenuButton}) {
    if (this.isFreeMode === false) { return }
    this.guidedInteractionServiceService.startInteraction(selectedTraining.content.value).subscribe()
  }

  public onItemSelected(selectedItem: Ooi, forcetransparent: boolean = false) {
    if (this.isFreeMode === false) { return }
    if (this.screwMode) {
      this.exitScrewMode()
    }
    if (forcetransparent) {
      this.modelService.toggleTransparent(true)
    }
    this.select(selectedItem)
    console.log(`Selected: ${selectedItem.id}`)
    const cameraSettings = selectedItem.getPreferredCameraSettings()
    this.babylonInteractionService.moveArcRotateCamera(
      this.selectedItem!.getMeshes()[0].absolutePosition,
      cameraSettings?.radius ?? 2,
      cameraSettings?.alpha ?? 4.0,
      cameraSettings?.beta ?? 1.0,
      1.8)
  }

  public onLanguageSelected(selectedItem: any) {
    this.translation.use(selectedItem)
  }

  public async onVariantSelected(selectedItem: any) {
    this.currentVariant = selectedItem.content
    await this.modelService.activateVariation(this.currentVariant)
  }

  public unselect() {
    if (this.selectedItem) {
      this.modelService.unselectOOI(this.selectedItem)
    }
    this.selectedItem = undefined
    this.dialogService.close()
  }

  // MARK

  private select(ooi: Ooi, showInfo = true) {
    if (this.selectedItem) {
      this.modelService.unselectOOI(this.selectedItem)
    }

    if (this.isShowDebug) {
      this.babylonInteractionService.positionEdit(ooi)
    }

    this.selectedItem = ooi
    console.log(ooi.id)

    if(showInfo === true) {
      ooi.show()
      const description = this.selectedItem?.getDescription(this.translation.currentLang)

      this.dialogService.show({
        hideButton: this.selectedItem,
        mainContent: [{
          type: "text",
          content: description != null && description.length > 0 ? description : "COMPONENTS.NO_DESCRIPTION",
        }],
        title: this.selectedItem?.getName(this.translation.currentLang) ?? "",
        playAudioPath: this.selectedItem.getAudioDescriptionPath(this.translation.currentLang) !== "" ? "../../../assets/audio/" + this.selectedItem.getAudioDescriptionPath(this.translation.currentLang) : undefined,
        isCollapsable: true,
        align: "right",

      }).subscribe(el => {
        switch (el.type) {
          case "BUTTON_PRESS":
            if (el.payload === "HIDE_SELECTED") {
              //ooi.hide()
            }
            if (el.payload === "SHOW_SELECTED") {
              //ooi.show()
            }

            break

          default:
            break
        } el.type
      })
    }

    this.modelService.selectOOI(ooi)

  }

  public setHighlightAnimated() {
    this.highlightAnimated = !this.highlightAnimated
    if (this.modelService.animationService)
      this.modelService.animationService.highlightAnimated = this.highlightAnimated
  }

  public setDebugMode() {
    this.isShowDebug = !this.isShowDebug
    if (this.isShowDebug === true) {
      this.bsEngineService.scene.debugLayer.show( { embedMode: true})
    } else {
      this.bsEngineService.scene.debugLayer.hide()
    }
    this.bsDebugMode()

  }

  public hideSelected() {
    if (this.selectedItem) {
      this.selectedItem.show()
    }
  }

  public showSelected() {
    if (this.selectedItem) {
      this.selectedItem.show()
    }
  }

  public showDebug() {
    this.bsEngineService.showDebug(true)
  }

  public getImageLink(ooi: Ooi): string | undefined {
    if (ooi.partId != "") {
      let variant = this.currentVariant as string
      let imageList = appinfoJson.images as { [index: string]: string[] }
      let list: string[] = imageList[variant]
      let filename = list.find(x => x.indexOf(ooi.partId) > -1)
      if (filename) {
        return "../../../assets/Image/" + filename
      }
    }
    return undefined
  }

  public getAudioLink(ooi: Ooi): string | undefined {
    if (ooi.partId != "") {
      let variant = this.currentVariant as string
      let audioList = appinfoJson.audios as { [index: string]: string[] }
      let list: string[] = audioList[variant]
      let filename = list.find(x => x.indexOf(ooi.partId) > -1)
      //console.log(filename)
      if (filename) {
        return "../../../assets/Audio/" + filename
      }
    }
    return undefined
  }

  public hasScrews(ooi: Ooi) {
    return this.screwInfoService.hasScrews(ooi)
  }

  public headerClick() {
    this.#headerClickSubject.next()
  }

  public onScrewClicked(ooi: Ooi) {
    if (!this.screwMode) {
      this.screwInfoService.highLightScrewGroup(ooi)
      this.screwMode = true
      this.unselect()
    }
  }

  public exitScrewMode() {
    this.modelService.toggleTransparent()
    this.screwMode = false
    this.modelService.resetModel()
    this.screwInfoService.exitScrewMode()
    this.picking = true
  }

  public enterAR() {
    this.xrManager.enterARMode()
  }

  public accordionStep(step : number, text : boolean = false) : string {
    if (step == 2) {
      if (text) { return "LEARNING_SECTION"}
      return "step2"
    }
    if (step == 3) {
      if (text) { return "COMPONENT_LEVEL"}
      return "step3"
    }
    return "uncoonfiguredStep{step}"
  }

  public onGlossarClick(template: TemplateRef<any>) {
    let options: ModalOptions = {}
    options.class = "big-modal"
    this.modalRef = this.modalService.show(template)
  }

  public showTutorial() {
    this.dialogService.show({
      title: "TUTORIAL.START_TITLE",
      closingBehavior: "CLOSABLE",
      mainContent: [{
        type: "text",
        content: "WELCOME_TRAINING"
      }],
      buttons: [{
        text: "HELP.LEARN_NAVIGATION",
        payload: "LEARN_NAVIGATION",
      },
        {
          text: "HELP.SKIP_INTRODUCTION",
          payload: "SKIP_INTRODUCTION",
        }]

    }, false).subscribe({
      complete: () => {
        this.doBlur = false
      },
      next: el => {
      console.log(el.type)
      switch (el.type) {
        case "BUTTON_PRESS":
          if (el.payload === "LEARN_NAVIGATION") {
            this.doBlur = false
            this.dialogService.close()
            this.startTour()
          }
          if (el.payload === "SKIP_INTRODUCTION") {
            this.doBlur = false
            this.showDialog()
          }
          break
        case "CLOSE":
          this.doBlur = false
              break
        default:
          break
      } el.type
    }})
  }

  public showDialog() {
    this.doBlur = true
    this.dialogService.show({
      title: "TUTORIAL.FINISH_TITLE",
      closingBehavior: "CLOSABLE",
      mainContent: [{
        type: "text",
        content: "FINISH_TUTORIAL",
      }],
      buttons: [{
        text: "GENERAL.START",
        payload: "START",
        isClosing: true
      }],
    }, false).subscribe({
      complete: () => {
        this.doBlur = false
  }
    })
  }

  public async copyCameraCoordinatesToClipBoard(value: any) {

    await navigator.clipboard.writeText(value)

  // Alert the copied text
    alert("Copied coordinates to clipboard")
  }

  public async copyToClipBoard(value: any) {
    await navigator.clipboard.writeText(value)

  // Alert the copied text
    alert("Copied coordinates to clipboard")
  }

}
