import { Injectable } from "@angular/core"
import { AbstractMesh, AssetContainer, Color3, PickingInfo } from "@babylonjs/core"
import { Ellipse, Line, Rectangle, TextBlock, AdvancedDynamicTexture, Button, Grid } from "@babylonjs/gui"
import { Model, Ooi} from "../models/model"
import { InspectorMainComponent } from "../pages/inspector-main/inspector-main.component"
import { Screw } from "../models/screw"
import { BabylonEngineService } from "./babylon-engine.service"
@Injectable({
  providedIn: "root"
})

export class ScrewInfoService {
  public model?: Model
  public container?: AssetContainer
  public screwMode: boolean = false
  public screws: Screw[] = []
  public screwMapping: any[] = []
  public inspector!: InspectorMainComponent
  public advancedTexture!: AdvancedDynamicTexture
  public constructor(
    private bsEngineService: BabylonEngineService
  ) { }

  public async init(model: Model, container: AssetContainer, csvPath: string) {
    this.container = container
    this.model = model
    let response = await fetch(csvPath)
    let text = await response.text()
    const lines = text.split("\r\n") //ugly, needs fixing
    for (let line of lines) {
      let entry = line.split(";")
      let screw = new Screw(this, entry)
      this.screws.push(screw)
    }
    this.unhighlightAllScrews()
  }

  public handleHit(mesh: AbstractMesh) {
    let screwHit = this.screws.find(x => x.mesh && x.mesh.visibility == 1 && x.mesh == mesh)
    if (screwHit) {
      if (screwHit.mesh)
        this.makeLabel(screwHit)
    }
  }

  public exitScrewMode() {
    this.unhighlightAllScrews()
    this.advancedTexture.dispose()
  }

  public findMesh(name: string) {
    if (this.container) {
      let node = this.container.getNodes().find(x => x.name == name)
      if (node) {
        let childMeshes = node.getChildMeshes()
        if (childMeshes && childMeshes.length > 0) {
          return childMeshes[0]
        }
      }
    }
    return undefined
  }

  public findParentOoi(ooiId: string) {
    if (this.model) {
      return this.model.oois.find(x => x.id == ooiId)
    }
    return undefined
  }

  public hasScrews(ooi: Ooi) {
    let result = this.screws.find(x => x.parentOoi && x.parentOoi == ooi)
    if (result) {
      return true
    }
    return false
  }

  public unhighlightAllScrews() {
    this.screws.forEach(x => {
      if (x.mesh) {
        x.mesh.renderOverlay = false
        x.mesh.visibility = 0
      }
    })
  }

  public highLightScrewGroup(ooi: Ooi) {
    this.unhighlightAllScrews()
    this.screwMode
    this.inspector.picking = false
    this.bsEngineService.ssao.totalStrength = 0
    this.advancedTexture = AdvancedDynamicTexture.CreateFullscreenUI("UI")
    this.model?.toggleTransparent(true)
    let relevantScrews = this.screws.filter(x => x.parentOoi && x.parentOoi == ooi)
    relevantScrews.forEach(x => {
      if (x.mesh) {
        x.mesh.overlayColor = new Color3(0, 1, 1)
        x.mesh.renderOverlay = true
        x.mesh.visibility = 1
      }
    })
  }

  public makeLabel(screw: Screw) {
    if (screw.mesh && this.advancedTexture) {

      var rect1 = new Rectangle()
      rect1.width = "270px"
      rect1.height = "100px"
      rect1.cornerRadius = 20
      rect1.color = "#ffffff"
      rect1.alpha = 0.75
      rect1.thickness = 0
      rect1.background = "white"
      this.advancedTexture.addControl(rect1)
      rect1.linkWithMesh(screw.mesh)
      rect1.linkOffsetYInPixels = -80

      var grid = new Grid()
      grid.background = "#ffffff00"
      this.advancedTexture.addControl(grid)

      grid.width = "250px"
      grid.height = "76px"

      grid.addColumnDefinition(0.25)
      grid.addColumnDefinition(0.75)
      grid.addColumnDefinition(24, true)

      grid.addRowDefinition(24, true)
      grid.addRowDefinition(24, true)
      grid.addRowDefinition(24, true)
      //grid.addRowDefinition(0.33);

      var button = Button.CreateSimpleButton("button", "x")
      button.top = "0px"
      button.left = "0px"
      button.width = "24px"
      button.height = "24px"
      button.cornerRadius = 20
      button.thickness = 1
      button.children[0].color = "#DFF9FB"
      button.children[0].fontSize = 20
      button.color = "#FF7979"
      button.background = "#EB4D4B"



      grid.addControl(button, 0, 2)


      var label = new TextBlock()
      label.text = " Type: "
      label.fontSize = 12
      label.fontWeight = "bold"
      label.textHorizontalAlignment = 0
      label.color = "black"
      grid.addControl(label, 0, 0)


      var label = new TextBlock()
      label.text = screw.type
      label.fontSize = 12
      label.textHorizontalAlignment = 0
      label.color = "black"
      grid.addControl(label, 0, 1)


      var label = new TextBlock()
      label.text = " Torque: "
      label.fontSize = 12
      label.fontWeight = "bold"
      label.textHorizontalAlignment = 0
      label.color = "black"
      grid.addControl(label, 1, 0)


      var label = new TextBlock()
      label.text = screw.torque
      label.fontSize = 12
      label.textHorizontalAlignment = 0
      label.color = "black"
      grid.addControl(label, 1, 1)


      var label = new TextBlock()
      label.text = " Glue: "
      label.fontSize = 12
      label.fontWeight = "bold"
      label.textHorizontalAlignment = 0
      label.color = "black"
      grid.addControl(label, 2, 0)


      var label = new TextBlock()
      label.text = screw.glue
      label.fontSize = 12
      label.textHorizontalAlignment = 0
      label.color = "black"
      grid.addControl(label, 2, 1)
      grid.linkWithMesh(screw.mesh)
      grid.linkOffsetYInPixels = -80


      //rect1.addControl(grid)


      var label = new TextBlock()
      label.text = " Type: " + screw.type +
        "\n" + " Torque: " + screw.torque +
        "\n" + " Loctite: " + screw.glue
      label.fontSize = 12
      label.textHorizontalAlignment = 0
      label.color = "black"
      //rect1.addControl(label);

      var target = new Ellipse()
      target.width = "10px"
      target.height = "10px"
      target.color = "white"
      target.thickness = 2
      target.background = "#008EA5"
      this.advancedTexture.addControl(target)
      target.linkWithMesh(screw.mesh)


      var line = new Line()
      line.lineWidth = 2
      line.y2 = 50
      line.linkOffsetY = -5
      this.advancedTexture.addControl(line)
      line.linkWithMesh(screw.mesh)
      line.connectedControl = rect1

      button.onPointerClickObservable.add(() => {
        target.dispose()
        line.dispose()
        rect1.dispose()
        grid.dispose()
      })


    }
  }
}
