import { NgIf } from "@angular/common"
import { Component, Input, OnDestroy, inject } from "@angular/core"
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser"

@Component({
  standalone: true,
  selector: 'app-pdf',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.scss'],
  imports: [NgIf]
})
export class PdfComponent implements OnDestroy {

  protected _url?: SafeResourceUrl

  private sanitizer = inject(DomSanitizer)

  @Input() public set url(url: string | undefined) {
    if(url == null) {
      this._url = undefined
      return
    }
    this._url = this.sanitizer.bypassSecurityTrustResourceUrl(url)
  }

  public ngOnDestroy(): void {
    console.log("destroy pdf")
  }

}
