import { Interaction } from "./utils"

export const trainings: Record<string,Interaction> = {
  "Replace charging cable":  {
    type: "MANUAL",
    onStart: [
      {
        type: "CLEAR_ALL_LABEL"
      }
    ],
    onFinished: [
      {
        type: "CLEAR_ALL_LABEL"
      }
    ],
    description: "This is a training for Ausfahren.",
    steps: [
      {
        id: 1,
        type: "GUIDED_DIALOG",
        title: "Open the Door",
        text: "Open the ***front door***",
        actions: [
          {
            type: "SHOW_ALL"
          },
          {
            type: "UNSELECT_ALL"
          },
          {
            type: "ARC_CAMERA",
            x: -0.3,
            y: 0.84,
            z: -0.01,
            alpha: 4.12,
            beta: 1.18,
            radius: 3.6,
            time: 1.8
          },
          {
            type: "CLEAR_ALL_LABEL"
          },
          {
            type: "LABEL_PART",
            value: "99001224813",
            text: "Motor (2)",
            options: {
              length: 150,
              direction: "up"
            }
          }],
        interactions: [
          {
            type: "DIALOG_BUTTON_PRESS",
            text: "GENERAL.NEXT",
            actions: [{
              type: "STEP",
              value: 2
            }]
          }]

      },
      {
        id: 2,
        type: "GUIDED_DIALOG",
        title: "Remove covers",
        text: "Remove the inner front cover and the top lid",
        actions: [
          {
            type: "UNSELECT_ALL"
          },
          {
            type: "ARC_CAMERA",
            "x":-0.1,"y":1.97,"z":0.41,"alpha":4.75,"beta":1.5,"radius":2.55,
            time: 1.8
          },
          {
            type: "CLEAR_ALL_LABEL"
          },{
            type: "HIDE_PART",
            value: "8EM5925-0AA00-1AA3 Charging Cable CCS2 500A 5m LCC - ERK"
          },
          {
            type: "HIDE_PART",
            value: "8EM5925-0AA00-1AA2 Charging Cable CCS2 250A 5m - ERK(left)_primitive1"
          }, {
            type: "ANIMATION",
            value: "FrontDoorAction",
            action: "PLAY"
          }],
        interactions: [
          {
            type: "DIALOG_BUTTON_PRESS",
            text: "GENERAL.NEXT",
            actions: [{
              type: "STEP",
              value: 3
            }]
          }]

      },
      {
        id: 3,
        type: "GUIDED_DIALOG",
        title: "Put the charging cable into place",
        text: "Place the charging cable into the opening and tighten it with a M40 or M50 wrench with 30nM ([Table](#table \"Table with content\"))",
        actions: [
          {
            type: "UNSELECT_ALL"
          },
          {
            type: "ARC_CAMERA",
            "x":-0.42,"y":1.97,"z":-0.49,"alpha":5.54,"beta":1.45,"radius":1.16,
            time: 1.8
          },
          {
            type: "CLEAR_ALL_LABEL"
          },
          {
            type: "LABEL_PART",
            value: "8EM5920-0AA00-2AA8 Cable holder",
            text: "M40/M50 wrench with 30nM",
            options: {
              length: 150,
              direction: "right"
            }
          },{
            type: "HIDE_PART",
            value: "8EM5920-0AA00-2AA8 Housing Front Cover"
          },
          {
            type: "HIDE_PART",
            value: "8EM5920-0AA00-2AA8 Housing Top"
          }],
        interactions: [
          {
            type: "DIALOG_BUTTON_PRESS",
            text: "GENERAL.NEXT",
            actions: [{
              type: "STEP",
              value: 4
            }]
          },
          {
            type: "DIALOG_EVENT",
            event: "table",
            actions: [{
              type: "STEP",
              value: 4
            },
            {
              type: "OPEN_WINDOW",
              windowType: "PDF",
              title: "Repair Manual",
              value: "/assets/Repair Instruction SICHARGE D.pdf#page=5&toolbar=1&statusbar=1&navpanes=1&help=index"
            },
            {
              type: "OPEN_WINDOW",
              windowType: "IMAGE",
              title: "Schönes Bild",
              value: "/assets/digital.jpg"
            }]
          }]

      },{
        id: 4,
        type: "GUIDED_DIALOG",
        title: "Guide cables",
        text: "Guide the cable through the holes into the roof section of the charger.",
        actions: [
          {
            type: "UNSELECT_ALL"
          },
          {
            type: "ARC_CAMERA",
            "x":0.14,"y":1.86,"z":0.71,"alpha":4.44,"beta":1.35,"radius":1.62,
            time: 1.8
          },
          {
            type: "CLEAR_ALL_LABEL"
          },
          {
            type: "LABEL_PART",
            value: "8EM5920-0AA02-4AA2 Mounting Frame Cable Hole",
            text: "Through here",
            options: {
              length: 150,
              direction: "up"
            }
          },
          {
            type: "LABEL_PART",
            value: "8EM5920-0AA02-4AA2 Mounting Frame Cable Hole",
            text: "and here",
            options: {
              length: 150,
              direction: "up",
              "originOffset": {"x":0.06,"y":0,"z":0},
            }
          },
          {
            type: "LABEL_PART",
            value: "8EM5920-0AA02-4AA2 Mounting Frame Cable Hole",
            text: "and here",
            options: {
              length: 150,
              direction: "up",
              "originOffset": {"x":0.106,"y":0.008,"z":0.043},
            }
          }
        ],
        interactions: [
          {
            type: "DIALOG_BUTTON_PRESS",
            text: "GENERAL.NEXT",
            actions: [{
              type: "STEP",
              value: 5
            }]
          }]

      },{
        id: 5,
        type: "GUIDED_DIALOG",
        title: "Remove DC Meter Cover",
        text: "Remove the cover to be able to connect the plus pol",
        actions: [
          {
            type: "UNSELECT_ALL"
          },
          {
            type: "ARC_CAMERA",
            "x":-0.29,"y":1.58,"z":-0.33,"alpha":6.16,"beta":0.09,"radius":1.18,
            time: 1.8
          },
          {
            type: "CLEAR_ALL_LABEL"
          },
          {
            type: "LABEL_PART",
            value: "8EM5925-0AA00-0AA4 Cover DC Meter - ERK",
            text: "Remove Cover DC Meter",
            options: {
              length: 150,
              direction: "right",
              "originOffset": {"x":0,"y":0,"z":-0.029},
            }
          },{
            type: "HIDE_PART",
            value: "8EM5920-0AA00-2AA8 Housing Main L-Class"
          },
          {
            type: "HIDE_PART",
            value: "8EM5920-0AA00-5AA0 Blackpanel 24inch CC RFID ES AC(closed door)"
          }],
        audio: "",
        interactions: [
          {
            type: "DIALOG_BUTTON_PRESS",
            text: "GENERAL.NEXT",
            actions: [{
              type: "STEP",
              value: 6
            }]
          }]

      },{
        id: 6,
        type: "GUIDED_DIALOG",
        title: "Cable connection",
        text: "Connect the cable to the DC Meter Sensor Unit. Connect DC+ to the DC TA Out 30x10. And DC- to the to the connection on the DC Meter 400A - ERK shown on the model. Tighten it with a maximum of 10nM.",
        actions: [
          {
            type: "UNSELECT_ALL"
          },
          {
            type: "ARC_CAMERA",
            "x":-0.29,"y":1.58,"z":-0.33,"alpha":6.16,"beta":0.09,"radius":1.18,
            time: 1.8
          },
          {
            type: "CLEAR_ALL_LABEL"
          },{
            type: "HIDE_PART",
            value: "8EM5925-0AA00-0AA4 Cover DC Meter - ERK"
          },{
            type: "HIDE_PART",
            value: "8EM5920-0AA00-2AA8 Housing Main L-Class"
          },
          {
            type: "HIDE_PART",
            value: "8EM5920-0AA00-5AA0 Blackpanel 24inch CC RFID ES AC(closed door)"
          },{
            type: "LABEL_PART",
            value: "8EM5920-0AA00-1AA0 DC TA Out 30x10(Pos2)",
            text: "DC TA Out 30x10 (DC+) connection",
            options: {
              length: 150,
              direction: "up"
            }
          },
          {
            type: "LABEL_PART",
            value: "8EM5925-0AA00-0AA2 DC Meter 400A - ERK(closed door)",
            text: "DC Meter 400A (DC-) connection",
            options: {
              length: 100,
              direction: "down"
            }
          }],
        audio: "",
        interactions: [
          {
            type: "DIALOG_BUTTON_PRESS",
            text: "GENERAL.NEXT",
            actions: [{
              type: "STEP",
              value: 7
            }]
          }]

      },{
        id: 7,
        type: "GUIDED_DIALOG",
        title: "Connect Grounding",
        text: "Connect the grounding cable. Not complete step",
        actions: [
          {
            type: "UNSELECT_ALL"
          },
          {
            type: "ARC_CAMERA",
            "x":-0.14,"y":2.25,"z":-0.61,"alpha":7.82,"beta":1.36,"radius":0.4,
            time: 1.8
          },
          {
            type: "CLEAR_ALL_LABEL"
          },{
            type: "HIDE_PART",
            value: "8EM5925-0AA00-0AA4 Cover DC Meter - ERK"
          },{
            type: "HIDE_PART",
            value: "8EM5920-0AA00-2AA8 Housing Main L-Class"
          },
          {
            type: "HIDE_PART",
            value: "8EM5920-0AA00-5AA0 Blackpanel 24inch CC RFID ES AC(closed door)"
          }],
        audio: "",
        interactions: [
          {
            type: "DIALOG_BUTTON_PRESS",
            text: "GENERAL.NEXT",
            actions: [{
              type: "STEP",
              value: 8
            }]
          }]

      },
      {
        id: 8,
        type: "GUIDED_DIALOG",
        title: "Congratulations",
        text: "Congratulations you have replaced the charging cable.",
        actions: [
          {
            type: "SHOW_ALL"
          },
          {
            type: "ARC_CAMERA",
            "x":-0.04,"y":1.1,"z":-0.46,"alpha":4.73,"beta":1.28,"radius":4.86,
            time: 1.8
          },
          {
           type: "WAIT_FOR",
           value: 2
          },
          {
            type: "ANIMATION",
            value: "FrontDoorAction",
            action: "REVERSE"
          },
          {
            type: "CLEAR_ALL_LABEL"
          }
        ],
        interactions: [
          {
            type: "DIALOG_BUTTON_PRESS",
            text: "GENERAL.DONE",
            isClosing: true,
            actions: [{
              type: "STEP",
              value: 6,
              payload: "Done"
            }]
          },
          {
            type: "DIALOG_BUTTON_PRESS",
            text: "GENERAL.TO_BEGINNING",
            isClosing: true,
            actions: [{
              type: "STEP",
              value: 1
            }]
          }
          ]
      }
    ]
  }
}
