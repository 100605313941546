import { Pipe, PipeTransform } from "@angular/core"
import { TranslationService } from "./translation.service"

@Pipe({
  name: "translation"
})
export class TranslationPipe implements PipeTransform {

  public constructor(private translationService: TranslationService) {}

  public transform(value: string): string {
    return this.translationService.translate(value)
  }

}
