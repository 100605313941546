import { NgIf } from "@angular/common"
import { Component, Input } from "@angular/core"

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  standalone: true,
  imports: [NgIf]
})
export class ImageComponent {
  @Input() public url?: string

}
